import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  updateUserDataAsAdminRoute,
  deleteUserRoute,
  registerRoute,
  getPagesDataRoute,
  getUsersTableRoute
} from '../../utils/routes';
import { LogMessageType, UsersTableInterface, regexemail } from '../../functions_interfaces/interfaces';
import { fetchUsersTable } from '../../functions_interfaces/functions';
import "../../styles/styles.css";
import { useTranslation } from 'react-i18next';



export default function UsersTable({ ActionLogReferences }: UsersTableInterface) {


    let usersData = useRef<any>([]);

    const [visibleFirstName, setVisibleFirstName] = useState('');
    const [visibleLastName, setVisibleLastName] = useState('');
    const [visibleNameAbbreviation, setVisibleNameAbbreviation] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [visibleMail, setVisibleMail] = useState('');
    const [visibleUserType, setVisibleUserType]= useState(2);
    const [telephone, setTelephone] = useState('');
    const [companyuuid, setCompanyUuid] = useState('');
    const [update, forceUpdate] = useState(false);


    const { t } = useTranslation();

    const [isUsersMounted, setIsUsersMounted] = useState(false);
    const [showInsertUserForm, setShowInsertUserForm] = useState(false);
    const [groups, setGroups] = useState<any[]>([]);
    const [pagesData, setPagesData] = useState([]);
/*
    descriptionBoxReferences.usersData = usersData.current; */
    useEffect(() => {
        setUsersData();
        getPagesData();
    }, [])

    async function setUsersData() {
        setIsUsersMounted(false);
        try {
          ActionLogReferences.updateLogString('Fetching Users data', LogMessageType.Pending);
            let UsersTable = await fetchUsersTable(getUsersTableRoute);
            console.log(UsersTable.data);

            if (UsersTable && UsersTable.resolved ) {

                usersData.current = UsersTable.data.sort((a, b) => {
                    return a.lastname.localeCompare(b.lastname);
                }); // set Main data structure to recieved data

                ActionLogReferences.updateLogString('Users data Transfer Success', LogMessageType.Success, JSON.stringify(UsersTable.data, null,"\t"));

                console.log('Data Transfer Success');

                setIsUsersMounted(true);
                forceUpdate(!update);

            } else {
              ActionLogReferences.updateLogString('Error fetching data!', LogMessageType.Error, JSON.stringify(UsersTable.message, null,"\t"));
            }
        }
        catch (e: any) {
            const msg = e.message + '\nCheck backend connection'
            alert(msg)
            ActionLogReferences.updateLogString('Error fetching data!', LogMessageType.Error, msg);
            console.log(msg);
        }
    }
    async function getPagesData () {
      try {
        let response = await axios.get(getPagesDataRoute);

        if (response && response.data.resolved) {
          ActionLogReferences.updateLogString('Page Data set', LogMessageType.Success, response.data.data);
          setPagesData(response.data.data);
        }
        else {
          ActionLogReferences.updateLogString ('Error fetching pages data', LogMessageType.Error, response.data.message);
        }
      }
      catch (e) {
        ActionLogReferences.updateLogString ('Error fetching data', LogMessageType.Error, e);
        console.log(e);
      }
    }

    async function insertNewUser(e) {
        e.preventDefault();

        const regexEmail = new RegExp(regexemail);
        if (!regexEmail.test(visibleMail)) {
          alert('E-pošta vsebuje neveljavne znake, oz. je napačnega formata!');
          return;
        } else {
          let registerData = {
            password1: password1,
            password2: password2,
            userData: {
              firstname: visibleFirstName,
              lastname: visibleLastName,
              email: visibleMail,
              usertype: visibleUserType,
              telephone: telephone,
              company: companyuuid,
              active: false,
              name_abbreviation: visibleNameAbbreviation
            },
            token: '1234'
          };
          let response;
          try {
            response = await axios.post(registerRoute, registerData);
          } catch (error) {
            let message = "No response && response.data!";
            alert(message);
            return;
          }

          if (response && response.data) {
            if (response.data.resolved === true) {
              setUsersData();
              return;
            } else {
              if (response.data.message) {
                alert(response.data.message);
                return;
              } else {
                let message = "No response.data.message!";
                alert(message);
                return;
              }
            }
          } else {
            let message = "No response && response.data!";
            alert(message);
            return;
          }
        }
      }


    async function deleteUser(email, uuid, index) {
        if (
          window.confirm(
            `Ali si resnično želite izbrisati uporabnika z emailom "${email}"?`
          )
        ) {
          try {
            const response = await axios.delete(deleteUserRoute, {
              data: { email, useruuid: uuid, index }
            });

            if (response && response.data.resolved === true) {
              ActionLogReferences.updateLogString(
                'User deleted',
                LogMessageType.Regular,
                response.data.message
              );
              setUsersData();
            } else {
              ActionLogReferences.updateLogString(
                'Error deleting user',
                LogMessageType.Error,
                response.data.message
              );
            }
          } catch (error) {
            // Handle error
          }
        }
      }

      function DataDisplay({ index, item }) {

        const [edit, setEdit] = useState(false);

        const [dropdown, setDropdown] = useState(false);
        const [mod_dropdown, setModDropDown] = useState(false);

        const [formData, setFormData] = useState({
          currentFirstname: item.firstname,
          currentLastname: item.lastname,
          currentEmail: item.email,
          currentTelephone: telephone,
          currentCompany: item.company,
          currentLanguage: item.language,
          currentInternal: item.is_internal_user,
          currentActive: item.active,
          currentType: item.usertype,
          currentPageAccess: item.page_access? item.page_access : [],
          currentPageModerator: item.page_moderator? item.page_moderator : [],
          currentNameAbbreviation: item.name_abbreviation? item.name_abbreviation: ''
        });

        const handleInputChange = (e) => {
          const { name, value } = e.target;
          setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        };

        async function handleSubmit () {
          let updateData = {...formData, useruuid: item.useruuid, currentPageAccess: formData.currentPageAccess.map(item => item.page_uuid), currentPageModerator: formData.currentPageModerator.map(item => item.page_uuid)}

          try {
            const response = await axios.put(updateUserDataAsAdminRoute, updateData)

              if (response && response.data.resolved) {
                setEdit(false);
                await setUsersData();
              }
              else {
                ActionLogReferences.updateLogString(
                  'Error updating user',
                  LogMessageType.Error,
                  response.data.message
                );
              }
          }
          catch (e) {
            ActionLogReferences.updateLogString(
              'Error updating user',
              LogMessageType.Error,
              e
            );
          }
        };
        function handlePageChange (page) {
          console.log("Handling page change for:", page.page_uuid); // Debugging
          const pageUuids = formData.currentPageAccess.map(item => item.page_uuid);

          if (pageUuids.includes(page.page_uuid)) {
            const updatedAccess = formData.currentPageAccess.filter(item => item.page_uuid !== page.page_uuid);
            console.log(updatedAccess)
            setFormData(prev => ({...prev, currentPageAccess: updatedAccess}));
          } else {
            const updatedAccess = [...formData.currentPageAccess, page];
            console.log(updatedAccess)
            setFormData(prev => ({...prev, currentPageAccess: updatedAccess}));
          }
        }
        function handleModeratorChange (page) {
          console.log("Handling page change for:", page.page_uuid); // Debugging
          const pageUuids = formData.currentPageModerator.map(item => item.page_uuid);

          if (pageUuids.includes(page.page_uuid)) {
            const updatedAccess = formData.currentPageModerator.filter(item => item.page_uuid !== page.page_uuid);
            console.log(updatedAccess)
            setFormData(prev => ({...prev, currentPageModerator: updatedAccess}));
          } else {
            const updatedAccess = [...formData.currentPageModerator, page];
            console.log(updatedAccess)
            setFormData(prev => ({...prev, currentPageModerator: updatedAccess}));
          }
        };

        return (
          <>

            {edit ? (
              <>
                {/* <td>{index + 1}</td> */}
                <td>
                  <input
                    type="text"
                    name="currentFirstname"
                    value={formData.currentFirstname}
                    onChange={handleInputChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="currentLastname"
                    value={formData.currentLastname}
                    onChange={handleInputChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="currentNameAbbreviation"
                    value={formData.currentNameAbbreviation}
                    onChange={handleInputChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="currentEmail"
                    value={formData.currentEmail}
                    onChange={handleInputChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="currentType"
                    value={formData.currentType}
                    onChange={handleInputChange}
                  />
                </td>
                <td className='page-container'>
                  <button
                    className="globals--regular-button"
                    onClick={() => setDropdown(!dropdown)}
                  >
                    Open
                  </button>

                  {dropdown && (
                    <div className="page-content"
                          style = {{display:dropdown?'':'none'}}>
                      <ul>
                      {pagesData.map((access: any, index) => (
                        <li key={index}>
                            <input
                              type="checkbox"
                              disabled = {formData.currentType == 0}
                              checked={formData.currentPageAccess?.map(item => item.page_uuid).includes(access.page_uuid)}
                              onChange={() => handlePageChange(access)}
                            />
                            {access.page_name}
                        </li>
                      ))}
                      </ul>
                    </div>
                  )}
                </td>
                <td className='page-container'>
                  <button
                    className="globals--regular-button"
                    onClick={() => setModDropDown(!mod_dropdown)}
                  >
                    Open
                  </button>

                  {mod_dropdown && (
                    <div className="page-content">
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        {formData.currentPageAccess?.map((page, index) => (
                          <li key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
                            <input
                              type="checkbox"
                              disabled={formData.currentType === 0}
                              checked={formData.currentPageModerator?.map(item => item.page_uuid).includes(page.page_uuid)}
                              onChange={() => handleModeratorChange(page)}
                              style={{ marginRight: '10px' }}
                            />
                            {page.page_name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </td>

                <td>
                  <input
                    type="text"
                    name="currentTelephone"
                    value={formData.currentTelephone}
                    onChange={handleInputChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="currentCompany"
                    value={formData.currentCompany}
                    onChange={handleInputChange}
                  />
                </td>
                <td>
                  <select onChange={handleInputChange} value={formData.currentLanguage} name="currentLanguage">
                    <option value = 'sl'>Slovenscina</option>
                    <option value = 'en'>English</option>
                    <option value = 'bs'>Bosanski</option>
                    <option value = 'ch'>中文</option>
                  </select>
                </td>
                <td>
                  <button onClick={() => setFormData((prev) => ({ ...prev, currentInternal: !prev.currentInternal }))}
                          className = {formData.currentInternal?'globals--green-button':'globals--red-button'}>
                    {String(formData.currentInternal)}
                  </button>
                </td>
                {/* <td>{String(item.registered.split('T')[0])}</td> */}
                <td>
                  <button onClick={() => setFormData((prev) => ({ ...prev, currentActive: !prev.currentActive }))}
                          className = {formData.currentActive?'globals--green-button':'globals--red-button'}>
                    {String(formData.currentActive)}
                  </button>
                </td>
                <td>
                  <button onClick={handleSubmit}
                          className = 'globals--green-button'>
                      {t('usersTable.table.headers.submit')}
                  </button>
                </td>
                <td>
                  <button onClick={() => deleteUser(item.email, item.useruuid, 1)}
                          className = 'globals--red-button'>
                      {t('usersTable.table.headers.delete')}
                  </button>
                </td>
              </>
            ) : (
              <>
                {/* <td>{index + 1}</td> */}
                <td>{formData.currentFirstname}</td>
                <td>{formData.currentLastname}</td>

                <td>{formData.currentNameAbbreviation}</td>
                <td>{formData.currentEmail}</td>
                <td>{formData.currentType}</td>
                <td className='page-container'>
                    <select value = {t('usersTable.table.headers.page_access')} defaultValue={formData.currentPageAccess[0]?.page_name} onChange={() => {}}>
                      <option>{t('usersTable.table.headers.page_access')}</option>
                      {formData.currentPageAccess?.map((access: any, index) => (
                        <option key={index}>
                            {access.page_name}
                        </option>
                      ))}
                    </select>
                </td>
                <td className='page-container'>
                    <select value = {t('usersTable.table.headers.page_access')} defaultValue={formData.currentPageModerator[0]?.page_name} onChange={() => {}}>
                      <option>{t('usersTable.table.headers.page_access')}</option>
                      {formData.currentPageModerator?.map((access: any, index) => (
                        <option key={index}>
                            {access.page_name}
                        </option>
                      ))}
                    </select>
                </td>
                <td>{formData.currentTelephone}</td>
                <td>{formData.currentCompany}</td>
                <td>{formData.currentLanguage}</td>
                <td>{String(formData.currentInternal)}</td>{/*
                <td>{String(item.registered.split('T')[0])}</td> */}
                <td>{String(formData.currentActive)}</td>
                <td>
                  <button className = 'globals--green-button' onClick={() => setEdit(true)}>{t('usersTable.table.headers.edit')}</button>
                </td>

              </>
            )}
          </>
        );
      }


      return (
        <div className = 'usersTable'>
          <header className='globals--module-navbar'>
            <div className = 'globals--module-navbar-title'>
              {t(`index.head.dropdown.users`)}
            </div>

            <div className = 'helpers--section-container-orientation-flex-row'>
              <button className = 'globals--regular-button'
                      onClick={() => setShowInsertUserForm(!showInsertUserForm)}>
                {t('usersTable.insertForm.button')}
              </button>
            </div>
          </header>
          <main className = 'subPageContent'>
              <>
                <div  className = 'resizable-popup'  style = {{display: showInsertUserForm?'':'none'}}>
                  <div className='controls'>
                    <button onClick={() => setShowInsertUserForm(!showInsertUserForm)}>
                      Close
                    </button>
                  </div>

                  <div className='insertUser'>


                    <form onSubmit={(e) => insertNewUser(e)} className = 'form'>
                        <div className='form-header'>
                            {t('usersTable.insertForm.form.header')}
                        </div>

                        <div className='form-row'>
                            <label>{t('usersTable.insertForm.form.name')}</label>
                            <input
                                type='text'
                                placeholder='Janez'
                                value={visibleFirstName}
                                onChange={(e) => setVisibleFirstName(e.target.value)}
                            />
                        </div>

                        <div className='form-row'>
                            <label>{t('usersTable.insertForm.form.surname')}</label>
                            <input
                                type='text'
                                placeholder='Novak'
                                value={visibleLastName}
                                onChange={(e) => setVisibleLastName(e.target.value)}
                            />
                        </div>

                        <div className='form-row'>
                            <label>{t('usersTable.table.headers.name_abbreviation')}</label>
                            <input
                                type='text'
                                placeholder='JN'
                                value={visibleNameAbbreviation}
                                onChange={(e) => setVisibleNameAbbreviation(e.target.value)}
                            />
                        </div>

                        <div className='form-row'>
                            <label>{t('usersTable.insertForm.form.password1')}</label>
                            <input
                                type='text'
                                value={password1}
                                onChange={(e) => setPassword1(e.target.value)}
                            />
                        </div>

                        <div className='form-row'>
                            <label>{t('usersTable.insertForm.form.password2')}</label>
                            <input
                                type='text'
                                value={password2}
                                onChange={(e) => setPassword2(e.target.value)}
                            />
                        </div>

                        <div className='form-row'>
                            <label>{t('usersTable.insertForm.form.email')}</label>
                            <input
                                type='text'
                                placeholder='janez.novak@test.com'
                                value={visibleMail}
                                onChange={(e) => setVisibleMail(e.target.value)}
                            />
                        </div>

                        <div className='form-row'>
                            <label>{t('usersTable.insertForm.form.type')}</label>
                            <input
                                type='text'
                                placeholder='0-a, 1-s, 2-r'
                                value={visibleUserType}
                                onChange={(e) => setVisibleUserType(Number(e.target.value))}
                            />
                        </div>

                        <div className='form-row'>
                            <label>{t('usersTable.insertForm.form.telephone')}</label>
                            <input
                                type='text'
                                placeholder='030303030'
                                value={telephone}
                                onChange={(e) => setTelephone(e.target.value)}
                            />
                        </div>

                        <div className='form-row'>
                            <label>{t('usersTable.insertForm.form.company')}</label>
                            <input
                                type='text'
                                placeholder='Zaenkrat ne izpolnjuj'
                                value={companyuuid}
                                onChange={(e) => setCompanyUuid(e.target.value)}
                            />
                        </div>

                        <button className='globals--regular-button' type='submit'>
                            {t('usersTable.insertForm.form.submit')}
                        </button>
                    </form>
                </div>

                </div>
                <table>
                  <thead>
                    <tr>
                      {/* <th>{t('usersTable.table.headers.index')}</th> */}
                      <th>{t('usersTable.table.headers.name')}</th>
                      <th>{t('usersTable.table.headers.surname')}</th>
                      <th>{t('usersTable.table.headers.name_abbreviation')}</th>
                      <th>{t('usersTable.table.headers.email')}</th>
                      <th>{t('usersTable.table.headers.type')}</th>
                      <th>{t('usersTable.table.headers.page_access')}</th>
                      <th>Urednik strani</th>
                      <th>{t('usersTable.table.headers.telephone')}</th>
                      <th>{t('usersTable.table.headers.company')}</th>
                      <th>{t('usersTable.table.headers.language')}</th>
                      <th>{t('usersTable.table.headers.internal')}</th>{/*
                      <th>{t('usersTable.table.headers.registered')}</th> */}
                      <th>{t('usersTable.table.headers.active')}</th>
                      <th>{t('usersTable.table.headers.edit')}</th>
                      <th>{t('usersTable.table.headers.delete')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData.current.map((item, index) => {
                      return (
                        <tr key={item.useruuid}>
                          <DataDisplay index = {index}
                                      item = {item}/>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>

          </main>
        </div>
      )
    }

import { QgisReferencesInterface } from "../Pages/qgis.repository/_qgis.repository";

export interface RecursiveInterface {
    data,

        selectedTags?;
        openFolderState?;
        openQuickInfoState?;
        forceUpdate;
        showAllQuickInfo?;
        selectedTable?: string;
        unfoldTree?: boolean ;
        refreshRecursiveParent?;
        refreshRecursiveParentState?;
        
        itemIndex?;
        moveItemsToggle?;
        setNewData?;
        fetchNewParentData?;

        DescriptionBoxReferences: DescriptionBoxReferences;
        ActionLogReferences: ActionLogReferences;
        OverViewReferences: OverViewReferences;
};

export interface MainInterface {
    recursiveParent: string, 
    selectTable: string,

    ActionLogReferences: ActionLogReferences,
    DescriptionBoxReferences: DescriptionBoxReferences,
    OverViewReferences: OverViewReferences
};

export interface DescriptionBoxInterface {
    tableName?: string;

    DescriptionBoxReferences: DescriptionBoxReferences;
    ActionLogReferences: ActionLogReferences;
};

export interface ActionLogInterface {
    ActionLogReferences : ActionLogReferences
};

export interface GeoTechnicsInterface {
    ActionLogReferences: ActionLogReferences
};

export interface GroupsInterface {
    ActionLogReferences: ActionLogReferences
};

export interface MonitoringInterface {
    MonitoringReferences: MonitoringReferences
    ActionLogReferences: ActionLogReferences
};

export interface ProfileInterface {
    userData, 
    loadUserDataFromToken,
    ActionLogReferences: ActionLogReferences
};

export interface UsersTableInterface {
    ActionLogReferences: ActionLogReferences,
};

export interface VirtualToursInterface {
    VirtualTourReferences: VirtualTourReferences
};

export interface OverviewInterface {
    OverViewReferences: OverViewReferences,
    DescriptionBoxReferences: DescriptionBoxReferences,
    ActionLogReferences: ActionLogReferences
};

export interface VirtualTourReferences {
    setViewedVirtualTour,
    getVirtualTourNodeTree
};

export interface UserControlPanelInterface {
    ActionLogReferences: ActionLogReferences
}
export interface mod_User {
    useruuid: string,
    firstname: string;
    lastname: string;
    is_internal: boolean;
    usertype: number;
    allowed_access_uuid: string,
    allowed_access: {
      read: boolean;
      edit: boolean;
      delete: boolean;
      download: boolean;
      upload: boolean;
    };
  }
export class NodeData {
    
    public node_id: string | null = null;
    public parent_node_id: string | null = null;
    public ref_node_id: string | null = null;
    public priority_number: number = 0;
    public name: string = '';
    public staff: staff[] = [];
    public path_info?: string | '';
    public description?: string | '';
    public status?: number | string;
    public node_type?: number | null = null;
    public subNodes?: NodeData[] = [];
    public tasks?: string[] | [];
    public divider?: boolean = false;
    public type_of_item?: string | 'undefined';
    public date_created?: string | null = null;
    public date_finished?: string | null = null;
    public date_submitted?: string | null = null;
    public deadline?: string | null = null;
    public file_name_submitted?: string | '';
    public sub_items?: string | '';
    public change_description?: string | '';
    public scale?: string | '';
    public class_id?: string | '';
   
    public section?: string | '****';
    public selected?: boolean;
    public listIndex?: number | null = null;
    public parent_node_name?: string;
    public tags: string | null = null;
};


export class staff {
    name: string | null = null;
    useruuid: string | null = null;
    name_abbreviation: string | null = null;
};

export interface OverViewReferences {
    nodeEntryPoint;
    disableButton: (boolean) => void;
};


export interface pdfReferences {
    fetchPDF;
}

export interface DescriptionBoxReferences {

    setDboxData?;
    dBoxData?;

    setShowDescriptionBox?;
    isDescriptionBoxShown?
    setDboxToInsertForm?;
    DboxInsertFormState?;
    setTypeOfInsertForm?;
    
    recSetCurrentlySelected?;
    updateRecursiveNode?;

    updatePrevItem?;
    currentData?;

    refreshRecursiveParent?;
    refreshRecursiveParentState?;

    fetchNewParentData?;

    updatePrevSelected?;
    setVisibleData?;

    recursiveSetVisibleData?;
    updateFlattenedData?;

    usersData?;
};

export interface UploadExcelInterface {
    OverViewReferences: OverViewReferences, 
    ActionLogReferences: ActionLogReferences, 
    DescriptionBoxReferences: DescriptionBoxReferences, 
    toggleInsertExcelVisibility: boolean, 
    setToggleInsertExcelVisibility: Function
}

export interface selectionListReferences {

    listItems?;
    setListItems?;

    setOpenDragLocation?;
    openDragLocation?: boolean;

    setAddedToList?;
    addedToList?;
    
    flattenedData?;
    scope?;
    force?;
    forceState?;

    setVisibleFromList?

    setUpdateRecursive?;
    updateRecursive?;
};
export interface HistoryLogItemProps {
    changeOwner: string;
    changeDescription: string;
    date: string; 
    newStatus: string;
    statusChange: string;
  }

export interface MonitoringReferences {

    getModuleNodetreeMenu;
    monitoringNodeData?;
    setShowInsertForm?;
    setHeadInfo?
};

export interface ActionLogReferences {
    updateLogString: Function | any;
};



export enum sectionIndex {
    
    updateComment = 0,
    updateDateCreated = 1,
    updateDateFinished = 2,
    updateDateSubmitted = 3,
    updatePriorityNumber = 4,
};

export enum descriptionBoxPart  {
    priorityNumber = 0,
    section = 1,
    status = 2,
    fileName = 3,
    typeOfItem = 4,
    tasks = 5,
    comment = 6,
    dateCreated = 7,
    dateFinished = 8,
    dateSubmitted = 9,
    pathInfo = 10,
    deadline = 11,
    fileNameSubmitted = 12,
    subItems = 13,
    divider = 14,
    change_description = 15,
    class_id = 16,
    scale = 17,
    tags = 18
};

export enum typeOfInteraction {
    updateExistingItem = 0,
    insertNewItem = 1
};

export module Icons {

    export const closeIcon = '\u274C'
    export const OpenBook = '\uD83D\uDCD6'
    export const check = '\u2713'
    export const bluePen = '\u270E'
    export const trashCan = '\uD83D\uDDD1'
    export const message = '\uD83D\uDDE8'

    export const greenSquare = '\uD83D\uDFE9'
    export const yellowSquare = '\uD83D\uDFE8'
    export const redSquare = '\uD83D\uDFE5'
    export const whiteSquare = '\u2B1C'

    export const blueInfoCircle = '\uD83D\uDEC8'
    export const greenBook = '\uD83D\uDCD7'
    export const redBook = '\uD83D\uDCD5'
    export const books = '\uD83D\uDCDA'
    
    export const arrowDown = '\u2B9F'
    export const arrowRight = '\u2B9E'
    export const arrowLeft = '\uD83E\uDC80'

    export const arrowDown2 = '\u23F7'
    export const arrowRight2 = '\u23F5'

    export const restricted = '\uD83D\uDEC7'
    export const magnifyingGlass = '\uD83D\uDD0D'
    export const warningLight = '\uD83D\uDEA8'
    export const copyToClip = '\uD83D\uDCCB'

    export const loading = '\u27F3'
    export const appended = '\uD83D\uDD88'
};

export class Item {
    public message: string = '';
    public fullErrorMessage: string = '';
    public icon?: string = '\uD83D\uDEA8';
    public color?: string = 'red';
    public time?: string = '';
};

export enum LogMessageType {
    Error = 0,
    Success = 1,
    Pending = 2,
    Regular = 3
};

export enum UserType {
    Super = 0,
    Admin = 1,
    Regular = 2,
    None = 100,
};

export enum LoginNavigation {
    Login,
    Load,
    ResetPassword
};

export type UserdataPropsToBeModified = 'firstname' | 'lastname' | 'email' | 'telephone';

export interface LoginData {
    email: string;
    password: string;
    Email?: string
};

export interface UserData {
    email: string;
    firstname?: string;
    lastname?: string;
    telephone?: string;
    uuid?: string;
    companyuuid?: string;
    registered?: string;
    usertype?: UserType;
    active?: boolean
    token?: string;
    language?: string;
    page_access?:[];
};

export interface RegisterData {
    password1: string;
    password2: string;
    userData: UserData;
    token?: string;
};

  export const regexemail =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export enum PageNumber {
    Login = 0,
    Main = 1,
    Users = 2,
    Profile = 3,
    Geo = 5,
    Monitoring = 6,
    Virtual_tour = 7,
    Home = 99,
    access_table = 11,
    qgis_repository = 8,
    ifc_repository = 4
}

export enum geoPage {
    geo_geotechnical_reports = 0,
    caves = 1,
    foundation = 2,
    supporting_walls = 3,
    supervision = 4,
    companies = 5,
    field_investigations = 6
}



  export interface Sensor {
    uuid: string;
    name: string;
    description: string | null;
    nodetype: string | null;
    owner_uuids: string | null;
    group_uuids: string | null;
    owner_permissions: string | null;
    group_permissions: string | null;
    other_internal_permissions: string | null;
    priority: string | null;
    active: boolean;
    parent_node_id: string;
    children: Sensor[];
  }
  export interface RenderSensorsProps {
    project: any;
    workplace: any;
    ActionLogReferences: ActionLogReferences;
    graphReferences?: any;
    MonitoringReferences?: any;
    
  }
  export interface RenderProjectsProps {
    projects: any;
    ActionLogReferences: ActionLogReferences;
    graphReferences?: any;
    MonitoringReferences?: any;
    
  }
  export interface RenderWorkplacesProps {
    project: any;
    ActionLogReferences: ActionLogReferences;
    graphReferences?: any;
    MonitoringReferences?: any;
    
  }
  export interface RenderSensortypesProps {
    project: any;
    workplaceData: any;
    ActionLogReferences: ActionLogReferences;
    graphReferences?: any;
    MonitoringReferences?: any;
    
  }
  export interface SetNewNodeProps {
    isProject: boolean;
    isSensor: boolean;
    isSensortype?: boolean;
    parentUUID: string;
    name: string;
    projectData?: any;       // Optional since they might not always be present
    workplaceData?: any;
    sensorData?: any;
    graphReferences: any;
    MonitoringReferences: any;
  }
  export interface SetNewVirtualTourNodeProps {
    isProject: boolean;
    isSensor: boolean;
    parentUUID: string;
    name: string;
    projectData?: any;       // Optional since they might not always be present
    workplaceData?: any;
    virtualTourData?: any;
    VirtualTourReferences: any;
  }
  export interface SetNewQgisNodeProps {
    isProject: boolean;
    isSensor: boolean;
    parentUUID: string;
    name: string;
    projectData?: any;       // Optional since they might not always be present
    workplaceData?: any;
    virtualTourData?: any;
    QgisReferences: QgisReferencesInterface;
  }
  
// External Libraries
import React, { useState, useRef, useEffect} from 'react';
import axios from 'axios';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import Ajv from 'ajv';
import { Document, Page, pdfjs } from 'react-pdf';
import MonacoEditor from 'react-monaco-editor';
// Styles and Icons
import { IoIosSettings, IoMdRefresh } from "react-icons/io";
import { RiFileTransferLine } from "react-icons/ri";
import { ActionLogReferences, LogMessageType, MonitoringReferences, PageNumber } from '../../functions_interfaces/interfaces';
import 'chartjs-adapter-moment';
import 'react-tooltip/dist/react-tooltip.css';
import "react-pdf/dist/esm/Page/TextLayer.css";

import { useTranslation } from 'react-i18next';
import { jsPDF } from 'jspdf';
import * as d3 from 'd3';

// Interfaces and Routes
import {
  Icons,
  Sensor,
  RenderWorkplacesProps,
  RenderProjectsProps,
  RenderSensorsProps,
  SetNewNodeProps,
  pdfReferences,
  MonitoringInterface
} from '../../functions_interfaces/interfaces';
import {
  addFileRoute,
  appendFileRoute,
  deleteFileRoute,
  downloadFileRoute,
  updatePdfFileRoute,
  getMeasurmentsRoute,
  getMonitoringNodeTreeRoute,
  reprocessFilesRoute,
  updateConfigJsonRoute,
  servePdfFileRoute,
  getFileNamesAndDateModifiedAtServerRoute,
  refreshFtpDataRoute,
  fetchFtpFileListRoute,
  updateDatabaseFTPdata,
  deleteMonitoringNodeRoute
} from '../../utils/routes';
import { geoPageNodetreeMenuInterface } from '../geotechnics/_geotechnical.repository';

interface DateRange {
  label: string;
  startDate: Date | null;
  endDate: Date | null;
}

const dateRanges: DateRange[] = [
  {
    label: 'Last 30 Days',
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  },
  {
    label: 'Last 3 Months',
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    endDate: new Date(),
  },
  {
    label: 'Last 6 Months',
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
    endDate: new Date(),
  },
  {
    label: 'Last Year',
    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    endDate: new Date(),
  },
  {
    label: 'All',
    startDate: null,
    endDate: null,
  },
];

let globalTooltipContent = '';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const backgroundPlugin = {
  id: 'backgroundPlugin',
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;

    // Remove JSON object syntax (quotes and braces)
    let tooltipContent = globalTooltipContent.replace(/["{}]/g, '');

    // Split the tooltip content into lines based on '\n'
    const lines = tooltipContent.split('\n');

    // Styling
    ctx.save();
    ctx.font = '16px Arial';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.fillStyle = 'rgba(105, 105, 105, 0.8)'; // Soft grey color

    // Calculate where to start the text
    let startX = chartArea.left + 10; // Start 10px from the left edge of the chart area
    let startY = chartArea.top + 10; // Start 10px from the top edge of the chart area

    // Draw each line
    lines.forEach((line) => {
      ctx.fillText(line, startX, startY);
      startY += 20; // Line height
    });

    ctx.restore();
  }
};

const customZeroLinePlugin = {
  id: 'zeroLinePlugin',
  beforeDraw: function(chart, args, options) {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;

    if (!xAxis || !yAxis) {
      return;
    }

    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = '#00a76d'; // Line color
    ctx.setLineDash([5, 5]); // Dashed line
    ctx.lineWidth = 1; // Line width
    ctx.moveTo(xAxis.left, yAxis.getPixelForValue(0));
    ctx.lineTo(xAxis.right, yAxis.getPixelForValue(0));
    ctx.stroke();

    // Draw vertical line at X = 0
    ctx.beginPath();
    ctx.moveTo(xAxis.getPixelForValue(0), yAxis.top);
    ctx.lineTo(xAxis.getPixelForValue(0), yAxis.bottom);
    ctx.stroke();
    ctx.restore();
  }
};


ChartJS.register(
  ...registerables,
  zoomPlugin,
  backgroundPlugin,
  customZeroLinePlugin
);

const ajv = new Ajv()

const jsonConfigFileTemplate = {
  type: 'object',
  properties: {
    x_axisName: { type: 'string' },
    y_axisName: { type: 'string' },
    x_axisType: { type: 'string', enum: ['linear', 'time'] },
    _COMMENT: {
      type: 'array',
      items: { type: 'string' },
    },
    headerRow: { type: 'integer' },
    startRow: { type: 'integer' },
    x_axis_data_row: { type: 'integer' },
    reinitialize_all_values: { type: 'boolean'},
    reinitialization_date:{ type: ['string', 'null'] },
    sensors: { type: 'array', items: { type: 'string' } },
    offsets: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          offset_1: { type: 'string' },
          sensors: { type: 'array', items: { type: 'string' } },
          start: { type: 'string' },
          end: { type: 'string' },
          offsetValue: { type: ['number', 'null'] },
        },
        required: ['offset_1', 'sensors', 'start', 'end', 'offsetValue'],
      },
    },
    sensor_groups: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          group_title: { type: 'string' },
          sensor_name_contains: { type: 'array', items: { type: 'string' } },
          individual_sensor_names: { type: 'array', items: { type: 'string' } },
        },
        required: ['group_title'],
      },
    },
    base_file: { type: 'string' },
    reportData: {
      type: 'object',
      properties: {
        Borehole: { type: 'string' },
        Project: { type: 'string' },
        Location: { type: 'string' },
        Northing: { type: 'string' },
        Easting: { type: 'string' },
        Collar: { type: 'string' },
        Spiral_correction: { type: 'string' },
        Collar_elevation: { type: 'string' },
        Reading_depth: { type: 'string' },
        Groove_azimuth: { type: 'string' },
        Base_reading: { type: 'string' },
        Applied_azimuth: { type: 'string' },
      },
    },
    exclude: { type: 'array' },
    files: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          file: { type: 'string' }
        },
        required: ['file'],
      },
    },
    _STYLE_COMMENT: {
      type: 'array',
      items: [
        { type: 'object', properties: { color: { type: 'string', description: "Can be a hex code (e.g., '#D61177') or basic color names (e.g., 'red')." } } },
        { type: 'object', properties: { linetype: { type: 'array', items: { type: 'string', enum: ['dotted', 'dashed', 'dashDot', 'solid'] } } } },
        { type: 'object', properties: { linewidth: { type: 'string', description: "A string containing a number value from 1 to infinity. Bigger number, thicker line." } } },
        { type: 'object', properties: { pointtype: { type: 'array', items: { type: 'string', enum: ['circle', 'rect', 'rectRounded', 'rectRot', 'cross', 'crossRot', 'star'] } } } },
        { type: 'object', properties: { pointsize: { type: 'string', description: "A string containing a number value from 1 to infinity. Bigger number, thicker point." } } }
      ]
    },
    colors: {
      type: 'object',
      additionalProperties: {
        type: 'object',
        properties: {
          color: { type: 'string' },
          linetype: { type: 'string' },
          linewidth: { type: 'string' },
          pointtype: { type: 'string' },
          pointsize: { type: 'string' }
        },
        required: ['color', 'linetype', 'linewidth', 'pointtype', 'pointsize']
      }
    },
    x_axis_max: { type: ['number', 'null', 'string'] },
    x_axis_min: { type: ['number', 'null', 'string'] },
    y_axis_max: { type: ['number', 'null'] },
    y_axis_min: { type: ['number', 'null'] },
    sensor_type: { type: 'string' },
    graph_titles: {
      type: 'array', // Making "graph_titles" an array of strings
      items: { type: 'string' }
    },
    plane_one_sensors: { type: 'array' },
    plane_two_sensors: { type: 'array' },
  },
  required: ['x_axisType', '_COMMENT', 'offsets', 'files'],
};
//------------------------------------------------------------------------------------------------------

export async function getModuleNodetreeMenu (pageNumber, route): Promise<Sensor[] | geoPageNodetreeMenuInterface[]> {
  try {
    let response = await axios.get(route, {
      params: {
        pageNumber: pageNumber
      }
    })

    if (response && response.data.resolved) {
      return (response.data.data as Sensor[])
    }
    else {
      return ([] as Sensor[])
    }
  }
  catch (e) {
    console.log(e);
    alert(e);
    return ([] as Sensor[]);
  }
}
//------------------------------------------------------------------------------------------------------
function Monitoring ({MonitoringReferences, ActionLogReferences}: MonitoringInterface) {

  const [monitoringTreeData, setMonitoringTreeData] = useState<Sensor[] | undefined>();
  const graphReferences: any = {};
  const chartRef1 = useRef<any>(null);
  const chartRef2 = useRef<any>(null);
  let showAllItems = useRef<boolean>(true);
  const legendRef = useRef<any>(null);

  const { t } = useTranslation();
  const [isMenuVisible, setIsMenuVisible]= useState(false);
  const [menuHover, setMenuHover] = useState(false);


  useEffect(() => {
    const hideMenu = () => {
      if (!menuHover) {
        setIsMenuVisible(false);
      }
    };

    // Hide menu with a delay to catch quick mouse movements between title and menu
    const timer = setTimeout(hideMenu, 100);

    return () => clearTimeout(timer);
  }, [menuHover]);

  useEffect(() => {
    getMonitoringNodeTreeInsideComponent();
  }, []);

  async function getMonitoringNodeTreeInsideComponent() {
    const monitoringNodeTreeData: Sensor[] = await getModuleNodetreeMenu(PageNumber.Monitoring, getMonitoringNodeTreeRoute) as Sensor[]
    setMonitoringTreeData(monitoringNodeTreeData)
  }


  MonitoringReferences.getModuleNodetreeMenu = getMonitoringNodeTreeInsideComponent

  return (
  <>
    <header className = 'globals--module-navbar'>
        <div className='globals--module-navbar-title' >
          {t(`index.head.dropdown.monitoring`)}
          <div className="globals--module-navbar-title-arrow"> {Icons.arrowRight} </div>

          {monitoringTreeData &&
            <div  className='globals--module-navbar-dropdown-tree-menu'>
              <RenderProjects projects={monitoringTreeData}
                              MonitoringReferences={MonitoringReferences}
                              graphReferences={graphReferences}
                              ActionLogReferences={ActionLogReferences}/>
            </div>}

        </div>
      <Head MonitoringReferences = {MonitoringReferences}
            graphReferences = {graphReferences}
            ActionLogReferences = {ActionLogReferences}/>
    </header>
    <div className="Monitoring-wrapper">

        <div>

</div>
              <GraphComponent graphReferences = {graphReferences}
                              MonitoringReferences = {MonitoringReferences}
                              chartRef1={chartRef1}
                              chartRef2={chartRef2}
                              legendRef={legendRef}
                              showAllLegendItems={showAllItems}
                              ActionLogReferences={ActionLogReferences}/>

      <div>
          <LegendComponent graphReferences = {graphReferences}/>
      </div>
    </div>
  </>
  );
};
//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------

type XAxisType = "time" | "linear";
interface MonitoringItem {
  name: string;
  isSensor: boolean;
  isProject: boolean;
  file_name: string;
  description: string;
  parent_node_id?: string;
  projectData;
  workplaceData;
  headerRow: number;
  startRow: number;
  x_axis_data_row: number;
  y_axisName: string;
  x_axisName: string;
  x_axisType: XAxisType;
  ftp_automated?: boolean;
  remote_path?: string;
  remote_file_identifier?: string;
}

export function InsertMonitoringNode ({MonitoringReferences, ActionLogReferences}) {

  const initMonitoringItem: MonitoringItem = {
    name: "Default Monitoring Item",
    isSensor: false,
    isProject: false,
    file_name: "default_file_name.txt",
    description: "",
    parent_node_id: undefined,
    projectData: '',
    workplaceData: '',
    headerRow: 1,
    x_axis_data_row: 1,
    startRow: 1,
    y_axisName: "",
    x_axisName: "",
    ftp_automated: false,
    remote_path: '',
    remote_file_identifier: '',
    x_axisType:'linear'
  };

  const [newItemState, setNewItemState]= useState<MonitoringItem>(initMonitoringItem);
  const [showComponent, setShowComponent] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selected, setSelected] = useState(false);
  const fileRef = useRef<any>(null);

  if (MonitoringReferences) {
    MonitoringReferences.setShowInsertForm = setShowComponent;
  }

  const handleNewNodeItemClose = () => {
      setShowComponent(false);
      setSelectedFile(null);
      setNewItemState(MonitoringReferences.data);
      setSelected(false);
      if (fileRef.current) {
        fileRef.current.value = null;
      }
  };
  const selectFileToUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setNewItemState(prev => ({ ...prev, description: selectedFile?.name.split('(')[0] as string}))
    setSelectedFile(selectedFile);
    setSelected(true);
  };

  async function submitFile() {
    try {
      const formData = new FormData();
      formData.append('nodeTreeItemData', JSON.stringify(newItemState));
      if (newItemState.isSensor) {
        if (!selectedFile) {
          window.alert('No file selected.');
          return;
        }
        formData.append('file', selectedFile);
      }



      const response = await axios.post(addFileRoute, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response && response.data.resolved) {
        setShowComponent(false);
        MonitoringReferences.getModuleNodetreeMenu(PageNumber.Monitoring, getMonitoringNodeTreeRoute);
        handleNewNodeItemClose();
      } else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
      }
    }
    catch (error: any) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
      alert(error);
    }
  }

  const handleFileSubmit = () => {
    submitFile();
  }

  const isButtonDisabled = () => {
    if (newItemState?.isSensor) {
      if (newItemState?.name.length > 0) {
        return !selected;
      } else {
        return true;
      }
    } else {
      return newItemState?.name.length <= 0;
    }
  };

  useEffect(() => {
      setNewItemState(MonitoringReferences?.data);
  },[MonitoringReferences?.data?.parentUUID]);


  return(
      <div className = 'insertNodeFormContainer' style = {{ display: showComponent? '':'none'}}>
          <div style = {{opacity: '1', backgroundColor: newItemState?.isProject? '#007BFF': newItemState?.isSensor?'#FFA500': '#008248'}} className='insertNodeForm'>
            <div className="gridContainer">
              {newItemState?.isProject &&
                <div>
                  <div>
                    <span>Projekt:</span>
                    <span>{newItemState?.name}</span>
                  </div>
                </div>
              }

              {newItemState?.isSensor &&
                <div>
                  <div>
                    <span>Project:</span>
                    <span>{newItemState?.projectData?.name}</span>
                  </div>
                  <div>
                    <span>Workplace:</span>
                    <span>{newItemState?.workplaceData?.name}</span>
                  </div>
                  <div>
                      <span>Sensor:</span>
                      <span>{newItemState?.name}</span>
                  </div>
                  <div>
                    <div className = 'upload'>
                      <input
                        ref={fileRef}
                        type="file"
                        onChange={selectFileToUploadHandler}
                        accept=".dat"
                      />
                    </div>
                  </div>
                </div>
              }

              {!newItemState?.isProject && !newItemState?.isSensor &&
                <div>
                  <div>
                    <span>Project:</span>
                    <span>{newItemState?.projectData?.name}</span>
                  </div>
                  <div>
                    <span>Workplace:</span>
                    <span>{newItemState?.name}</span>
                  </div>
                </div>
              }
            </div>
            {newItemState?.isSensor?
            <>
              <select
                  style = {{gridColumn: 'span 2'}}
                  onChange={(e) => setNewItemState(prev => ({ ...prev, name: e.target.value}))}>
                <option value = ''>Select measurement type...</option>
                <option value="Inclinometer">Inclinometer</option>
                <option value="Crack meter">Crack meter</option>
                <option value="Strain Gauge">Strain Gauge</option>
                <option value="Extensometer">Extensometer</option>
                <option value="Load Cell">Load Cell</option>
                <option value="Pressure Cell">Pressure Cell</option>
                <option value="Tiltmeter">Tiltmeter</option>
              </select>
{/*               <div style = {{display: 'flex',justifyContent:'space-between', flexDirection: 'row',gridColumn:'span 2'}}>
                <span>Description:</span>
                <input type = 'text'
                        placeholder='Name of the file etc...'
                        style = {{width:'30%'}}
                        onChange={(e) => setNewItemState(prev => ({ ...prev, description: e.target.value }))}
                        value = {newItemState.description}/>
              </div> */}
              <div style = {{display: 'flex',justifyContent:'space-between', flexDirection: 'row',gridColumn:'span 2'}}>
                <span>Header row index:</span>
                <input type = 'number'
                        onChange={(e) => setNewItemState(prev => ({ ...prev, headerRow: Number(e.target.value) }))}
                        value = {newItemState.headerRow}/>
              </div>
              <div style = {{display: 'flex',justifyContent:'space-between',  flexDirection: 'row',gridColumn:'span 2'}}>
                <span>X-axis column index:</span>
                <input type = 'number'
                        onChange={(e) => setNewItemState(prev => ({ ...prev, x_axis_data_row: Number(e.target.value) }))}
                        value = {newItemState.x_axis_data_row}/>
              </div >
              <div style = {{display: 'flex',justifyContent:'space-between',  flexDirection: 'row',gridColumn:'span 2'}}>
                <span>Data start index:</span>
                <input type = 'number'
                        onChange={(e) => setNewItemState(prev => ({ ...prev, startRow: Number(e.target.value) }))}
                        value = {newItemState.startRow}/>
              </div>

              <div style = {{display: 'flex',justifyContent:'space-between',  flexDirection: 'row',gridColumn:'span 2'}}>
                <span>X-axis name:</span>
                <input type = 'text'
                        onChange={(e) => setNewItemState(prev => ({ ...prev, x_axisName: e.target.value }))}
                        value = {newItemState.x_axisName}/>
              </div>
              <div style = {{display: 'flex',justifyContent:'space-between',  flexDirection: 'row',gridColumn:'span 2'}}>
                <span>X-axis Data Type:</span>
                <select
                  defaultValue='time'
                  onChange={(e) => setNewItemState(prev => ({ ...prev, x_axisType: e.target.value as XAxisType}))}
                >
                  <option value='time'>Time</option>
                  <option value='linear'>Linear</option>
                </select>

              </div>
              <div style = {{display: 'flex',justifyContent:'space-between',  flexDirection: 'row',gridColumn:'span 2'}}>
                <span>Y-axis name:</span>
                <input type = 'text'
                        onChange={(e) => setNewItemState(prev => ({ ...prev, y_axisName: e.target.value }))}
                        value = {newItemState.y_axisName}/>

              </div>

              <div style = {{display: 'flex',justifyContent:'space-between',  flexDirection: 'row',gridColumn:'span 2'}}>
                <span>Automate from FTP:</span>
                <input  type = 'checkbox'
                        onChange={() => setNewItemState(prev => ({ ...prev, ftp_automated: !newItemState.ftp_automated }))}
                        checked = {newItemState.ftp_automated}/>
              </div>
              {newItemState.ftp_automated &&
                <>
                  <div style = {{display: 'flex',justifyContent:'space-between',  flexDirection: 'row',gridColumn:'span 2'}}>
                    <span>remote_path:</span>
                    <input type = 'text'
                            onChange={(e) => setNewItemState(prev => ({ ...prev, remote_path: e.target.value }))}
                            value = {newItemState.remote_path}/>

                  </div>
                  <div style = {{display: 'flex',justifyContent:'space-between',  flexDirection: 'row',gridColumn:'span 2'}}>
                    <span>remote_file_identifier:</span>
                    <input type = 'text'
                            onChange={(e) => setNewItemState(prev => ({ ...prev, remote_file_identifier: e.target.value }))}
                            value = {newItemState.remote_file_identifier}/>

                  </div>
                </>

              }
            </>
            :
            <>
              <textarea placeholder='Insert Name'
                        onChange = {(e) => setNewItemState(prev => ({...prev, name: e.target.value}))}
                        value = {newItemState?.name}
                        style = {{gridColumn: 'span 2'}}/>

            </>
            }
            <button onClick = {/* () => newMonitoringTreeItem(true, newItemState?.name) */handleFileSubmit}
                    disabled = {isButtonDisabled()}
                    className = 'globals--green-button'>
              Confirm
            </button>
            <button onClick = {handleNewNodeItemClose}
                    className = 'globals--red-button'>
              Cancel
            </button>
          </div>
      </div>
  )
}

//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
function Head ({ MonitoringReferences, graphReferences, ActionLogReferences }) {

  let headData = {
    name:'',
    fileName:'',
  };

  const [headInfo, setHeadInfo] = useState<any>(headData);
  const fileRef = useRef<any>(null);
  const pdfFileRef = useRef<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedFiles, setSelectedFiles] = useState<any>();
  const [isTextAreaVisible, setTextAreaVisible] = useState(false);
  const [jsonConfigText, setJsonConfigText] = useState<any>('');
  const [isJsonSyntaxValid, setIsJsonSyntaxValid] = useState(true);
  const [isFilesAtServerWindowOpen, setFilesAtServerWindowOpen] = useState(false);
  const [isDeleteFilesWindowOpen, setDeleteFilesWindowOpen] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [areFtpSettingsOpen, setAreFtpSettingsOpen] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<DateRange>(dateRanges[0]);

  useEffect(()=> {
    setSelectedRange(dateRanges[0]);
  }, [headInfo])


  const pdfReferences: pdfReferences = {fetchPDF: null}

  MonitoringReferences.setJsonConfigText = setJsonConfigText;
  graphReferences.headInfo = headInfo;

  async function handleDownloadZip() {
    try {
      const response = await axios.post(downloadFileRoute, { folderName: headInfo.fileName }, {
        responseType: 'blob',
      });

      if (response.status === 200) {
        console.log(response);
        const contentDisposition = response.headers['content-disposition'];
        const match = contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = match && match[1] ? match[1] : `${headInfo.fileName}.zip`;

        const zipFileToDownload = new Blob([response.data], { type: 'application/zip' });

        const link = document.createElement('a');
        link.download = filename;
        link.href = window.URL.createObjectURL(zipFileToDownload);

        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
      }
    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
      alert(error);
    }
  };

  const selectFileToUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };
  const toggleTextAreaVisibility = () => {
    setTextAreaVisible(prevState => !prevState);
  };
  const toggleMapVisibility = () => {
    setIsMapVisible(prevState => !prevState);
  };

  const selectPDFFileToUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    setSelectedFile(selectedFile);
  };




  const DateDropdown: React.FC = () => {
    const [customStartDate, setCustomStartDate] = useState<Date | null>(null);
    const [customEndDate, setCustomEndDate] = useState<Date | null>(null);

    const handleRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedLabel = e.target.value;
      const range = dateRanges.find((range) => range.label === selectedLabel);
      if (range) {
        setSelectedRange(range);
        if (range.label !== 'Custom Range') {
          setCustomStartDate(null);
          setCustomEndDate(null);
        }
      }

      graphReferences.fetchData(headInfo.fileName, range?.startDate, range?.endDate);
    };

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomStartDate(new Date(e.target.value));
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomEndDate(new Date(e.target.value));
    };

    const getCurrentRange = () => {
      if (selectedRange.label === 'Custom Range' && customStartDate && customEndDate) {
        return { startDate: customStartDate, endDate: customEndDate };
      }
      return { startDate: selectedRange.startDate, endDate: selectedRange.endDate };
    };


    return (
      <div>
        <select value={selectedRange.label} onChange={handleRangeChange}>
          {dateRanges.map((range) => (
            <option key={range.label} value={range.label}>
              {range.label}
            </option>
          ))}
        </select>

{/*         {selectedRange.label === 'Custom Range' && (
          <div>
            <label>
              Start Date:
              <input type="date" onChange={handleStartDateChange} />
            </label>
            <label>
              End Date:
              <input type="date" onChange={handleEndDateChange} />
            </label>
          </div>
        )} */}
      </div>
    );
  };

  async function submitAndUpdateFile(e) {
    try {
      if (!selectedFiles || selectedFiles.length === 0) {
        window.alert('No files selected.');
        return;
      }

      const formData = new FormData();
      formData.append('fileName', headInfo.fileName);

      selectedFiles.forEach(file => {
        formData.append('files', file);
      });

      const response = await axios.post(appendFileRoute, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response && response.data.resolved) {
        setSelectedFiles([]);
        graphReferences.fetchData(headInfo.fileName, selectedRange.startDate, selectedRange.endDate);
        if (fileRef.current) {
          fileRef.current.value = '';
        }
      }
       else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
      }
    }
    catch (error: any) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
      alert(error);
    }
  }
  async function submitAndUpdatePdfFile() {
    try {
      const formData = new FormData();
      formData.append('updateFile', JSON.stringify({ fileName: headInfo.fileName}));
        if (!selectedFile) {
          window.alert('No file selected.');
          return;
        }
        formData.append('file', selectedFile);

      const response = await axios.post(updatePdfFileRoute, formData, { // -----------------------------------------------------------
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response) {
        if (response.data.resolved) {
          pdfReferences.fetchPDF();
        }
        else {
          ActionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            JSON.stringify(response.data.message, null, "\t")
          );
        }
      }

    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
      alert(error);
    }
  }

  async function updateConfigFile () {

    let updateData = {
      fileName: headInfo.fileName,
      data: jsonConfigText
    }
    try {
      let response = await axios.post(updateConfigJsonRoute, updateData);

      if (response && response.data.resolved) {
        toggleTextAreaVisibility();
        graphReferences.fetchData(headInfo.fileName, selectedRange.startDate, selectedRange.endDate)
      }
      else {
        ActionLogReferences.updateLogString(
          'Error',
          LogMessageType.Error,
          response.data.message
        );
      }
    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Error',
        LogMessageType.Error,
        error
      );
      alert(error);
    }
  }
  function handleJsonTextChange(changedText) {
    setJsonConfigText(changedText);

    try {
      const parsedJson = JSON.parse(changedText);

      const validate = ajv.compile(jsonConfigFileTemplate);
      const isValid = validate(parsedJson);

      setIsJsonSyntaxValid(isValid);

      if (isValid) {
        console.log('JSON is valid');
      } else {
        console.log('JSON is invalid');
      }
    } catch (error) {
      setIsJsonSyntaxValid(false);
      console.log('JSON syntax error:', error);
    }
  }
  async function reprocessAllTextFiles () {
    try {
      let response = await axios.post(reprocessFilesRoute, {fileName: headInfo.fileName});

      if (response && response.data.resolved) {
        setTextAreaVisible(false);
        graphReferences.fetchData(headInfo.fileName, selectedRange.startDate, selectedRange.endDate)
        ActionLogReferences.updateLogString(
          'Success',
          LogMessageType.Success,
          response.data.message
        );

      }
      else {
        ActionLogReferences.updateLogString(
          'Error',
          LogMessageType.Error,
          response.data.message
        );
      }
    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Error',
        LogMessageType.Error,
        error
      );
      alert(error);
    }
  }

  async function refreshFtpData(): Promise<void> {
    try {
      const response = await axios.put(refreshFtpDataRoute);

      if (response && response.data.resolved ) {
        alert('Successfully refreshed FTP data.');
        graphReferences.fetchData(headInfo.fileName, selectedRange.startDate, selectedRange.endDate);
      }
      else {
        ActionLogReferences.updateLogString(
          'Backend error',
          LogMessageType.Error,
          response.data.message
        );
        alert('Backend error');
      }
    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Frontend error',
        LogMessageType.Error,
        error
      );
      alert(error);
    }
  }

  const filesAtServer = () => {
    setFilesAtServerWindowOpen(true);
  };

  const deleteFiles = () => {
    setDeleteFilesWindowOpen(true);
  };

  interface DropdownOption {
    label: string;
    action: () => void;
  }

  const DropdownMenu: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState<string>('');

    const options: DropdownOption[] = [
      { label: 'Map', action: toggleMapVisibility },
      { label: 'PDF Report', action: () => graphReferences.downloadPDF() },
      { label: 'Configuration', action: toggleTextAreaVisibility },
      { label: 'Files .zip', action: handleDownloadZip },
      { label: 'Files at portal server', action: filesAtServer },
      { label: 'Files at CONFIG.json', action: deleteFiles },
      ...(headInfo.ftp_automated
        ? [{ label: 'FTP server', action: () => setAreFtpSettingsOpen(!areFtpSettingsOpen) }]
        : []),
    ];

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selected = e.target.value;
      setSelectedOption(selected);
      const selectedAction = options.find(option => option.label === selected)?.action;
      if (selectedAction) {
        selectedAction();
      }
    };

    return (
      <div>
        <select value={selectedOption} onChange={handleSelectChange}>
          <option value="" disabled>Menu</option>
          {options.map(option => (
            <option key={option.label} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>

      </div>
    );
  };

  MonitoringReferences.setHeadInfo = setHeadInfo;
  return (
    <>
{/*       <div className = 'globals--module-navbar-subTitle helpers--section-container-orientation-flex-row'>
        {headInfo.name}
      </div> */}
      {headInfo.name !== '' && (
        <>
      <div className = 'helpers--section-container-orientation-flex-row mobile-hide'>

        <input
          ref={fileRef}
          type="file"
          onChange={selectFileToUploadHandler}
          accept=".dat, .csv"
          multiple
          className = 'globals--regular-button'
        />

        <button onClick={(e) => submitAndUpdateFile(e)} className = 'globals--regular-button'>
          Submit
        </button>

      </div>
      <div className = 'helpers--section-container-orientation-flex-row mobile-hide'>

        <DateDropdown/>
      </div>

      {isTextAreaVisible && (
        <div className='full-screen-div-lock'>
          <div className='resizable-popup'>
            <div className="content">
              <MonacoEditor
                width="100%"
                height='100%'
                language="json"
                theme="vs-dark"
                value={jsonConfigText}
                options={{
                  selectOnLineNumbers: true,
                  automaticLayout: true,
                }}
                onChange={(newValue) => handleJsonTextChange(newValue)}
              />
            </div>
            <div className="controls">
              <button disabled={!isJsonSyntaxValid}
                      onClick={() => updateConfigFile()}
                      className = 'globals--regular-button'>
                Confirm
              </button>
              <button onClick={toggleTextAreaVisibility}
                      className = 'globals--regular-button'>
                Cancel
              </button>
              <button onClick={() => reprocessAllTextFiles()}
                      className = 'globals--regular-button'>
                Reprocess
              </button>
            </div>
          </div>
        </div>
      )}


      {isMapVisible &&
        <div className = 'full-screen-div-lock'>
          <div className='resizable-popup'>
            <div className="monaco-editor-container">
              <MyLocalPDFViewer pdfFileName={headInfo.fileName}
                              ActionLogReferences={ActionLogReferences}
                                pdfReferences = {pdfReferences}/>
            </div>
            <div className="controls">
              <div className="head-item">
                <input
                  ref={pdfFileRef}
                  type="file"
                  onChange={selectPDFFileToUploadHandler}
                  accept=".pdf"
                />
              </div>
              <button onClick={() => submitAndUpdatePdfFile()}>
                Submit
              </button>
              <button onClick={toggleMapVisibility}>
                Cancel
              </button>
            </div>
          </div>
        </div>}
        <div>

       {isFilesAtServerWindowOpen && (
          <div className="resizable-popup">
            <FilesAtServerComponent
              ActionLogReferences={ActionLogReferences}
              graphReferences={graphReferences}
              config={JSON.parse(jsonConfigText)}
              onClose={() => setFilesAtServerWindowOpen(false)}
              directory={headInfo.fileName}
              remote_file_identifier={headInfo.sensorData.remote_file_identifier}
              reprocessAllTextFiles={reprocessAllTextFiles}
            />
            <div className='controls'>
              <button onClick={() => setFilesAtServerWindowOpen(false)} className="globals--red-button">Close</button>
            </div>
          </div>
        )}

        {isDeleteFilesWindowOpen && (
          <div className="resizable-popup">
            <DeleteFilesComponent
              ActionLogReferences={ActionLogReferences}
              graphReferences={graphReferences}
              config={JSON.parse(jsonConfigText)}
              onClose={() => setDeleteFilesWindowOpen(false)}
              directory={headInfo.fileName}
              reprocessAllTextFiles={reprocessAllTextFiles}
            />
            <div className='controls'>
              <button onClick={() => setDeleteFilesWindowOpen(false)} className="globals--red-button">Close</button>
            </div>
          </div>
        )}

        {areFtpSettingsOpen && (
          <div className="resizable-popup">
            <FtpSettings
              ftp_automated={headInfo.ftp_automated as boolean}
              node_uuid={headInfo.sensorData.uuid}
              ActionLogReferences={ActionLogReferences}
              remote_file_identifier={headInfo.sensorData.remote_file_identifier}
              remote_path={headInfo.sensorData.remote_path}
              refreshFtpData={refreshFtpData}
            />
            <div className="controls">
              <button onClick={() => setAreFtpSettingsOpen(false)} className="globals--red-button">Close</button>
            </div>
          </div>
        )}
        </div>
        <DropdownMenu/>
        </>
      )}
      </>

  );
}

//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
interface FileAtServerInfo {
  name: string;
  size: number;
  mtime: string;
}

function FilesAtServerComponent({ config, onClose, directory, remote_file_identifier, reprocessAllTextFiles, graphReferences, ActionLogReferences }) {
  const [filesAtServerInfo, setFilesAtServerInfo] = useState<FileAtServerInfo[]>();
  const [relativeDirectoryPath, setRelativeDirectoryPath] = useState<string>();

  useEffect(() => {
    getFileNamesAndDateModifiedAtServer(directory);
  }, []);
  
  async function getFileNamesAndDateModifiedAtServer(directory: string): Promise<void> {

    try {
      let response = await axios.put(getFileNamesAndDateModifiedAtServerRoute, {directory});

      if (response && response.data.resolved) {
        setFilesAtServerInfo(response.data.data.filesInfo);
        setRelativeDirectoryPath(response.data.data.relativeDirectoryPath);
      }
      else {
        ActionLogReferences.updateLogString(
          'Error fetching fileInfo from server!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
        alert('Could not get fileInfo from server!');
      }
    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Error fetching fileInfo from server!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
      alert(error);
    }
  };

  return (
    <div className='global-list'>
      <h3>Files at server <span style={{fontSize: '12px'}} title='(relativeDirectoryPath at server) (remote_file_identifier)'>({relativeDirectoryPath})    ({remote_file_identifier})</span></h3>
      <table>
        <thead>
          <tr>
            <th>File name (modified) (size)</th>
          </tr>
        </thead>
        <tbody>
          {filesAtServerInfo?.map((fileAtServerInfo, index) => (
            <tr key={index}>
              <td><b>{fileAtServerInfo.name}</b> ({fileAtServerInfo.mtime}) ({fileAtServerInfo.size} B)</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}


//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
function DeleteFilesComponent({ config, onClose, directory, reprocessAllTextFiles, graphReferences, ActionLogReferences }) {


  async function updateBaseFile (newBaseFile) {

    try {
      let response = await axios.post(updateConfigJsonRoute, {fileName: directory, data: JSON.stringify({...config, base_file: newBaseFile})});

      if (response && response.data.resolved) {
        graphReferences.fetchData(directory);
      }
      else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
      }
    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
      alert(error);
    }
  };

  async function deleteThisFile(fileName) {
    const isConfirmed = window.confirm(`Are you sure you want to delete ${fileName}?`);

    if (isConfirmed) {
      try {
        let response = await axios.post(deleteFileRoute, {directory, fileName});

        if (response && response.data.resolved) {
          onClose();
          await reprocessAllTextFiles();
        }
        else {
          ActionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            JSON.stringify(response.data.message, null, "\t")
          );
        }
      }
      catch (error) {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(error, null, "\t")
        );
        alert(error);
      }
    }
  };

  return (
    <div className='global-list'>
      <h3>Files at CONFIG.json</h3>
      <table>
        <thead>
          <tr>
            <th>File Name</th>
            <th>Base</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {config.files?.map((fileObj, index) => (
            <tr key={index}>
              <td>{fileObj.file}</td>
              <td><button  onClick={() => updateBaseFile(fileObj.file)}className = {fileObj.file === config.base_file?'globals--green-button':'globals--regular-button'}>Base</button></td>
              <td><button onClick={() => deleteThisFile(fileObj.file)} className = 'globals--red-button'>Delete</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
//ˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇ6 Functions for creating tree structureˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇ
function RenderSensors({
  project,
  workplace,
  graphReferences,
  MonitoringReferences,
  ActionLogReferences
}: RenderSensorsProps) {

  const handleNewSensorClick = () => {
    setNewTreeItemBasedOnType({
      isProject: false,
      isSensor: true,
      parentUUID: workplace.uuid,
      name: '',
      projectData: project,
      workplaceData: workplace,
      graphReferences: graphReferences,
      MonitoringReferences: MonitoringReferences
    });
  };

  return (
    <>
      <div
        onClick={handleNewSensorClick}
        className='add-new-item sensor'
      >
        new sensor
      </div>

      {workplace.children.map(sensor => (
        <SensorItem
          key={sensor.uuid}
          label={sensor.name}
          description={sensor.description}
          fileName={sensor.file_name}
          project={project}
          sensorData = {sensor}
          ftp_automated = {sensor.ftp_automated}
          workplace={workplace}
          MonitoringReferences={MonitoringReferences}
          graphReferences={graphReferences}
          ActionLogReferences = {ActionLogReferences}

        >
          {/* Optionally render sensor details here */}
        </SensorItem>
      ))}
    </>
  );
}
//------------------------------------------------------------------------------------------------------
function RenderWorkplaces({
  project,
  graphReferences,
  MonitoringReferences,
  ActionLogReferences
}: RenderWorkplacesProps) {


  const handleNewWorkplaceClick = () => {
    setNewTreeItemBasedOnType({
      isProject: false,
      isSensor: false,
      parentUUID: project.uuid,
      name: '',
      projectData: project,
      graphReferences: graphReferences,
      MonitoringReferences: MonitoringReferences
    });
  };

  return (
    <>
      <div
        onClick={handleNewWorkplaceClick}
        className='add-new-item workplace'
      >
        new workplace
      </div>

      {project.children.map(workplace => (
        <TreeItem key={workplace.uuid}
                  label={workplace.name}
                  nodeData = {workplace}
                  ActionLogReferences = {ActionLogReferences}
                  MonitoringReferences = {MonitoringReferences}>
          <RenderSensors
            project={project}
            workplace={workplace}
            graphReferences={graphReferences}
            MonitoringReferences={MonitoringReferences}
            ActionLogReferences={ActionLogReferences}
          />
        </TreeItem>
      ))}
    </>
  );
}
//------------------------------------------------------------------------------------------------------
function RenderProjects({
  projects,
  graphReferences,
  MonitoringReferences,
  ActionLogReferences
}: RenderProjectsProps) {

  const handleNewProjectClick = () => {
    setNewTreeItemBasedOnType({
      isProject: true,
      isSensor: false,
      parentUUID: '',
      name: '',
      graphReferences: graphReferences,
      MonitoringReferences: MonitoringReferences
    });
  };

  return (
    <>
      <div
        onClick={handleNewProjectClick}
        className='add-new-item project'
      >
        new project
      </div>

      {projects.map(project => (
        <TreeItem key={project.uuid}
                  label={project.name}
                  ActionLogReferences = {ActionLogReferences}
                  nodeData = {project}
                  MonitoringReferences = {MonitoringReferences}>
          <RenderWorkplaces
            project={project}
            graphReferences={graphReferences}
            MonitoringReferences={MonitoringReferences}
            ActionLogReferences={ActionLogReferences}
          />
        </TreeItem>
      ))}
    </>
  );
};
//------------------------------------------------------------------------------------------------------
const TreeItem = (({ label, children, nodeData, ActionLogReferences, MonitoringReferences }: any) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
      setIsExpanded(!isExpanded);
  };
  return (
      <div className="TreeItem">
          <div onClick={toggleExpand} className="TreeItem-label">
              {isExpanded ? Icons.arrowDown : Icons.arrowRight}
              {label}
              <button onClick = {() => deleteMonitoringNode(nodeData.uuid, ActionLogReferences, MonitoringReferences)}
                      className = 'globals--red-button'>
                  delete
              </button>
          </div>
          {isExpanded && <div className="TreeItem-children">{children}</div>}

      </div>
  );
});
async function deleteMonitoringNode(nodeID, ActionLogReferences, MonitoringReferences: MonitoringReferences): Promise<void> {

  if (window.confirm(`Do you really want to delete this node and all of it's subnodes?` )) {
    try {

      let response = await axios.delete(deleteMonitoringNodeRoute, { data: { uuid: nodeID }, headers: { "Authorization": "***" } })

      if (response && response.data.resolved) {
        MonitoringReferences.setHeadInfo({name:'', fileName:''});
        MonitoringReferences.getModuleNodetreeMenu();
        ActionLogReferences.updateLogString(
          'fetching data OK!',
          LogMessageType.Success,
          JSON.stringify(response.data.message, null, "\t")
        );
      }
      else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
      }

    } catch (error) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
      alert(error);
    }
  }
}
//------------------------------------------------------------------------------------------------------
const SensorItem = (({
  label,
  fileName,
  project,
  workplace,
  MonitoringReferences,
  graphReferences,
  description,
  ftp_automated,
  sensorData,
  ActionLogReferences }: any) => {

  const handleDeleteButtonClick = async(e) => {
    e.stopPropagation();
    await deleteMonitoringNode(sensorData.uuid, ActionLogReferences, MonitoringReferences)
  }

  const isInclinometer = fileName.includes("Inclinometer");
  return (
      <div className="TreeItem">


          <div  className="TreeItem-label"
            onClick={() =>
              {graphReferences.fetchData(fileName, !isInclinometer? dateRanges[0].startDate : null,!isInclinometer? dateRanges[0].endDate: null);
              MonitoringReferences.setHeadInfo({name:`${project.name}-${workplace.name}-${label}`,
                                                fileName: fileName,
                                                fileDownload: null,
                                                jsonDownload: null,
                                                typeOfSensor: label,
                                                ftp_automated: ftp_automated,
                                                sensorData: sensorData}); }}>

                {label}
                <button onClick = {handleDeleteButtonClick}
                        className = 'globals--red-button'>
                  delete
                </button>

          </div>

      </div>
  );
});
//------------------------------------------------------------------------------------------------------
function setNewTreeItemBasedOnType({
  isProject,
  isSensor,
  parentUUID,
  name = '',
  projectData,
  workplaceData,
  sensorData,
  graphReferences,
  MonitoringReferences
}: SetNewNodeProps) {

  const monitoringData = {
    name,
    isProject,
    isSensor,
    parentUUID,
    projectData,
    workplaceData,
    sensorData,
    headerRow: 1,
    startRow: 2,
    x_axis_data_row: 1,
    x_axisName: "",
    x_axisType: "time",
    y_axisName: "",
    map_center: { lng: 14.506154188752362, lat: 46.084917510757776 }, // initial location to IRGO
    zoom: 17,
    rotation: 0,
    pitch: 0
  };

  MonitoringReferences.data = monitoringData;
  MonitoringReferences.setShowInsertForm(true);
}
//----------------------------------------------------6-------------------------------------------------
//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
function LegendComponent({graphReferences}){
/*   const [text, setText] = useState('');

  const searchItems= () => {
    graphReferences.setSearchText(text);
  } */

  return (
    <>
        <div className = 'charts' style = {{display: graphReferences.legendRef? '' : 'none'}}>
            <div className = 'legendName'>Legend</div>{/*
            <input
              type="text"
              placeholder="Search..."
              value={text}
              onChange={(e) => setText(e.target.value)}
            /> */}{/*
            <button onClick = {searchItems}>
              Search
            </button> */}

            <div className="chart-legend" ref={graphReferences.legendRef}></div>
        </div>
</>
  )
}
const GraphComponent = ({ graphReferences, MonitoringReferences, chartRef1, chartRef2, legendRef, showAllLegendItems, ActionLogReferences }) => {

  const tableConfiguration = {
    x_axisName: "",
    x_axisType: "time",
    y_axisName: "",
    offsets: [
        {
          offset_1: "",
          sensors: [],
          start: "",
          end: "",
          offsetValue: ""
        }
      ],
      exclude: []

  }

  interface InclinometerDataPoint {
    x: number;
    y: number;
  }

  interface InclinometerDataSet {
      [key: string]: InclinometerDataPoint[]; // Dynamic property names
  }

  interface InclinometerData {
      [fileName: string]: InclinometerDataSet; // File names as keys
  }

  interface rawInclinometerDataInterface {
    planeOneData_raw : InclinometerData[] | null;
    planeTwoData_raw : InclinometerData[] | null;
  }


  const [data, setGraphData]= useState<any>([]);


  const [planeOneData, setPlaneOneData] = useState<any>([]);
  const [planeTwoData, setPlaneTwoData] = useState<any>([]);
  const rawInclinometerData = useRef<rawInclinometerDataInterface>({planeOneData_raw: null, planeTwoData_raw: null})
  const [graphConfig, setGraphConfig] = useState<any>(tableConfiguration);
  const [showGraph, setShowGraph] = useState(false);
  const [searchText, setSearchText] = useState("");
  const graphKey = graphConfig.sensor_type === 'Inclinometer' ? 'two-graphs' : 'one-graph';
  const [graphType, setGraphType] = useState('raw');

  graphReferences.showGraph = showGraph;
  graphReferences.legendRef = legendRef;
  graphReferences.setSearchText = setSearchText
  graphReferences.searchText = searchText

  //--------------------------------------------------------------------------------------------------------------------------------------
  /**
 * Calculates mean deviation values for the given plane data based on the difference between positive and negative datasets.
 *
 * @param {Array} planeDataRaw - The raw data for each plane, structured as an array of objects with filenames as keys.
 * @param {Object} graphConfig - The configuration for the graph, including the type of x-axis.
 * @param {string} colorsDefault - The default color for the datasets, defaulting to 'rgba(0, 100, 0, 1)'.
 *
 * @returns {Array} datasets - The processed datasets with mean deviation calculations applied.
 */

  function calculateMeanDeviation(planeDataRaw, graphConfig, colorsDefault = 'rgba(0, 100, 0, 1)') {
    const datasets: any = [];

    planeDataRaw.forEach(item => {
      const fileName = Object.keys(item)[0];
      const planeData = item[fileName];

      const positiveDataSetKey: any = Object.keys(planeData).find(key => key.includes('plus'));
      const negativeDataSetKey: any = Object.keys(planeData).find(key => key.includes('minus'));

      const positiveDataSet = planeData[positiveDataSetKey];
      const negativeDataSet = planeData[negativeDataSetKey];

      const meanDeviationsDataPoints = positiveDataSet.map(positivePoint => {
        const negativePoint = negativeDataSet.find(point => point.x === positivePoint.x);
        let meanDeviation = 0;

        if (negativePoint) {
          meanDeviation = (positivePoint.y - negativePoint.y) / 2;
        }

        return {
          y: graphConfig.x_axisType === 'linear' ? parseFloat(positivePoint.x) : new Date(positivePoint.x),
          x: meanDeviation !== null ? meanDeviation : null
        };
      });

      datasets.push(createDatasetProperties(fileName, graphConfig, colorsDefault, 'Mean Deviation', meanDeviationsDataPoints, getRandomPointStyle));
    });

    return datasets;
  }

//--------------------------------------------------------------------------------------------------------------------------------------
/**
 * Calculates checksum values for the given plane data based on the difference between positive and negative datasets.
 *
 * @param {Array} planeDataRaw - The raw data for each plane, structured as an array of objects with filenames as keys.
 * @param {Object} graphConfig - The configuration for the graph, including the type of x-axis.
 * @param {string} colorsDefault - The default color for the datasets, defaulting to 'rgba(0, 100, 0, 1)'.
 *
 * @returns {Array} datasets - The processed datasets with checksum calculations applied.
 */
  function calculateCheckSum(planeDataRaw, graphConfig, colorsDefault = 'rgba(0, 100, 0, 1)') {
    const datasets: any = [];

    planeDataRaw.forEach(item => {
      const fileName = Object.keys(item)[0];
      const planeData = item[fileName];

      const positiveDataSetKey: any = Object.keys(planeData).find(key => key.includes('plus'));
      const negativeDataSetKey: any = Object.keys(planeData).find(key => key.includes('minus'));

      const positiveDataSet = planeData[positiveDataSetKey];
      const negativeDataSet = planeData[negativeDataSetKey];

      const checksumDataPoints = positiveDataSet.map(positivePoint => {
        const negativePoint = negativeDataSet.find(point => point.x === positivePoint.x);
        let checksum = 0;

        if (negativePoint) {
          checksum = (positivePoint.y - negativePoint.y);
        }

        return {
          y: graphConfig.x_axisType === 'linear' ? parseFloat(positivePoint.x) : new Date(positivePoint.x),
          x: checksum !== null ? checksum : null
        };
      });

      datasets.push(createDatasetProperties(fileName, graphConfig, colorsDefault, 'Checksum', checksumDataPoints, getRandomPointStyle));
    });

    return datasets;
  }
//--------------------------------------------------------------------------------------------------------------------------------------

/**
 * Calculates the incremental differences between consecutive datasets for the given plane data.
 *
 * @param {Array} planedata - The data for each plane, structured as an array of datasets.
 * @param {Object} graphConfig - The configuration for the graph, including factors for linear calculation.
 * @param {string} colorsDefault - The default color for the datasets, defaulting to 'rgba(0, 100, 0, 1)'.
 *
 * @returns {Array} datasets - The processed datasets with incremental differences applied.
 */
  function calculateIncremental(planedata, graphConfig, colorsDefault ='rgba(0,100,0,1)') {
    let meanDeviation = calculateMeanDeviation(planedata, graphConfig, colorsDefault);
    const datasets: any = [];

    for (let datasetIndex = 1; datasetIndex < meanDeviation.length; datasetIndex++) {
      const currentDataset = meanDeviation[datasetIndex];
      const previousDataset = meanDeviation[datasetIndex - 1];

      let modifiedDataset = JSON.parse(JSON.stringify(currentDataset));
      modifiedDataset.sensorType = 'Incremental';
      modifiedDataset.data = currentDataset.data.map(currentPoint => {
        const previousPoint = previousDataset.data.find(p => p.y === currentPoint.y);
        const incrementalX = previousPoint ? currentPoint.x - previousPoint.x : currentPoint.x;

        return {
            ...currentPoint,
            x: incrementalX
        };
      });

      datasets.push(modifiedDataset);
    }

    return datasets;
  }
//--------------------------------------------------------------------------------------------------------------------------------------
/**
 * Calculates the cumulative sum of incremental differences for the given plane data.
 *
 * @param {Array} planedata - The data for each plane, structured as an array of datasets.
 * @param {Object} graphConfig - The configuration for the graph, including factors for linear calculation.
 * @param {string} colorsDefault - The default color for the datasets, defaulting to 'rgba(0, 100, 0, 1)'.
 *
 * @returns {Array} datasets - The processed datasets with cumulative sums applied.
 */

  function calculateCumulative(planedata, graphConfig, colorsDefault ='rgba(0,100,0,1)') {
    let incremental = calculateIncremental(planedata, graphConfig, colorsDefault);
    const datasets: any = [];

    for (let datasetIndex = 0; datasetIndex < incremental.length; datasetIndex++) {
      const currentDataset = incremental[datasetIndex];

      // Clone the current dataset structure
      let modifiedDataset = JSON.parse(JSON.stringify(currentDataset));
      let cumulativeSum = 0;
      modifiedDataset.sensorType = 'Cumulative';
      modifiedDataset.data = currentDataset.data.slice().reverse().map(currentPoint => {
        cumulativeSum += currentPoint.x;
        return {
            ...currentPoint,
            x: cumulativeSum
        };
      }).reverse();

      datasets.push(modifiedDataset);
    }

    return datasets;
  }
//--------------------------------------------------------------------------------------------------------------------------------------
  /**
 * Calculates the cumulative sum of mean deviations for the given plane data.
 *
 * @param {Array} planedata - The data for each plane, structured as an array of datasets.
 * @param {Object} graphConfig - The configuration for the graph, including factors for linear calculation.
 * @param {string} colorsDefault - The default color for the datasets, defaulting to 'rgba(0, 100, 0, 1)'.
 *
 * @returns {Array} datasets - The processed datasets with cumulative sums applied.
 */
  function calculateAbsolute(planedata, graphConfig, colorsDefault ='rgba(0,100,0,1)') {
    let meanDeviation = calculateMeanDeviation(planedata, graphConfig, colorsDefault);
    const datasets: any = [];

    for (let datasetIndex = 0; datasetIndex < meanDeviation.length; datasetIndex++) {
      const currentDataset = meanDeviation[datasetIndex];

      // Clone the current dataset structure
      let modifiedDataset = JSON.parse(JSON.stringify(currentDataset));
      let cumulativeSum = 0;
      modifiedDataset.sensorType = 'Cumulative';
      modifiedDataset.data = currentDataset.data.slice().reverse().map(currentPoint => {
        cumulativeSum += currentPoint.x;
        return {
            ...currentPoint,
            x: cumulativeSum
        };
      }).reverse();

      datasets.push(modifiedDataset);
    }

    return datasets;
  }
  //--------------------------------------------------------------------------------------------------------------------------------------
  /**
   * Calculates linear values for a given plane data based on specified factors.
   *
   * @param {Array} planedata - The data for each plane, structured as an array of datasets.
   * @param {Object} graphConfig - The configuration for the graph, including factors for linear calculation.
   * @param {string} colorsDefault - The default color for the datasets, defaulting to 'rgba(0, 100, 0, 1)'.
   * @param {number} zeroReading - The zero reading value to be subtracted from each data point's y-value.
   *
   * @returns {Array} datasets - The processed datasets with linear calculations applied.
 */
  function calculateLinearCalculation(planedata, graphConfig, colorsDefault ='rgba(0,100,0,1)', zeroReading = 0) {
    const datasets: any = [];
    const gaugeFactor = graphConfig.linear_calculation.gauge_factor || 3.718;
    const BatchFactor = graphConfig.linear_calculation.batch_factor || 0.982

    for (let datasetIndex = 0; datasetIndex < planedata.length; datasetIndex++) {
      const currentDataset = planedata[datasetIndex];

      // Clone the current dataset structure
      let modifiedDataset = JSON.parse(JSON.stringify(currentDataset));
      let singleDataLinearCalculation = 0;
      modifiedDataset.sensorType = 'Linear calculation';

      modifiedDataset.data = currentDataset.data.map(currentPoint => {
        singleDataLinearCalculation = gaugeFactor * BatchFactor * (currentPoint.y - zeroReading);
        return {
            ...currentPoint,
            y: singleDataLinearCalculation
        };
      })

      datasets.push(modifiedDataset);
    }

    return datasets;
  }
//--------------------------------------------------------------------------------------------------------------------------------------
/**
 * Creates dataset properties for graphing.
 *
 * @param {string} filename - The name of the file associated with the dataset.
 * @param {Object} graphConfig - The configuration for the graph, including styles and file information.
 * @param {string} colorsDefault - The default color for the datasets.
 * @param {string} sensorType - The type of sensor for the dataset.
 * @param {Array} dataPoints - The data points for the dataset.
 * @param {Function} getRandomPointStyle - Function to get a random point style if not specified in the config.
 *
 * @returns {Object} - The configured dataset properties.
 */
function createDatasetProperties(filename, graphConfig, colorsDefault, sensorType, dataPoints, getRandomPointStyle) {

  const file = graphConfig.files.find(f => f.file === filename);
  const readingDate = file && file.data && file.data.readingDate ? new Date(file.data.readingDate).toLocaleDateString() : 'Unknown Date';


  let styleConfig = graphConfig.styles && graphConfig.styles[filename] ? graphConfig.styles[filename] : null;

  let backgroundColor = styleConfig && styleConfig.color ? styleConfig.color : colorsDefault;
  let borderColor = styleConfig && styleConfig.color ? styleConfig.color : colorsDefault;
  let borderWidth = styleConfig && styleConfig.linewidth ? parseInt(styleConfig.linewidth) : 1;
  let customPointStyle = styleConfig && styleConfig.pointtype ? styleConfig.pointtype : getRandomPointStyle();
  let pointSize = styleConfig && styleConfig.pointsize ? parseInt(styleConfig.pointsize) : undefined;

  let borderDash;
  switch (styleConfig?.linetype) {
    case 'dotted':
      borderDash = [2, 2];
      break;
    case 'dashed':
      borderDash = [5, 5];
      break;
    case 'dashDot':
      borderDash = [5, 2, 1, 2];
      break;
    case 'solid':
    default:
      borderDash = [];
      break;
  }

  return {
    label: graphConfig.sensor_type === 'Inclinometer'?`${filename} (${readingDate})`: filename,
    sensorType: sensorType,
    data: dataPoints,
    fill: false,
    backgroundColor: backgroundColor,
    borderColor: borderColor,
    borderWidth: borderWidth,
    borderDash: borderDash,
    pointStyle: customPointStyle,
    pointRadius: pointSize
  };
}
//--------------------------------------------------------------------------------------------------------------------------------------
/**
 * Processes raw inclinometer plane data and returns datasets for graphing.
 *
 * @param {Array} planeData - The raw data for each plane, structured as an array of objects with filenames as keys.
 * @param {Object} graphConfig - The configuration for the graph, including the type of x-axis.
 * @param {string} colorsDefault - The default color for the datasets, defaulting to 'rgba(0, 100, 0, 1)'.
 *
 * @returns {Array} datasets - The processed datasets ready for graphing.
 */

function processInclinometerPlaneData_RAW(planeData, graphConfig, colorsDefault = 'rgba(0, 100, 0, 1)') {
  const datasets: any = [];

  planeData.forEach(dataObject => {
    const filename = Object.keys(dataObject)[0];
    const sensorTypes = dataObject[filename];

    Object.keys(sensorTypes).forEach(sensorName => {

      if (Array.isArray(sensorTypes[sensorName])) {
        const dataPoints = sensorTypes[sensorName].map(point => ({
          y: graphConfig.x_axisType === 'linear' ? parseFloat(point.x) : new Date(point.x),
          x: point.y !== null ? parseFloat(point.y) : null
        }));

        const dataset = createDatasetProperties(filename, graphConfig, colorsDefault, sensorName, dataPoints, getRandomPointStyle);
        datasets.push(dataset);
      }
    });
  });

  return datasets;
}
//-------------------------------------------------------------------------------------------------------------------------------------------------
/**
 * Processes plane data and returns datasets for graphing.
 *
 * @param {Object} planeData - The data for each plane, structured as an object where keys are sensor names and values are arrays of data points.
 * @param {Object} graphConfig - The configuration for the graph (JSON), including whether to reinitialize all values and the type of x-axis.
 * @param {string} colorsDefault - The default color for the datasets, defaulting to 'rgba(0, 100, 0, 1)'.
 *
 * @returns {Array} datasets - The processed datasets ready for graphing.
 */
function processPlaneData(planeData, graphConfig, colorsDefault = 'rgba(0, 100, 0, 1)') {
  const datasets: any = [];

  Object.keys(planeData).forEach(sensorName => {
    let minXValue;
    if (graphConfig.reinitialize_all_values === true) {
      minXValue = planeData[sensorName].reduce((min, point) => {
        const xValue = graphConfig.x_axisType === 'linear' ? parseFloat(point.x) : new Date(point.x);
        if (xValue !== null && (min === null || xValue < min.x)) {
          return point.y
        }
        return min;
      }, null);
    }

    const dataPoints = planeData[sensorName].map(point => {

      let yValue
      if (graphConfig.reinitialize_all_values === true) {
        yValue = point.y !== null
          ? parseFloat(point.y) - minXValue
          : null;
      } else {
        yValue = point.y !== null
          ? parseFloat(point.y)
          : null;
      }

      const xValue = graphConfig.x_axisType === 'linear'
        ? parseFloat(point.x)
        : new Date(point.x);

      return { x: xValue, y: yValue };
    });


    datasets.push(createDatasetProperties(sensorName, graphConfig, colorsDefault, sensorName, dataPoints, getRandomPointStyle));
  });

  return datasets;
}
//-------------------------------------------------------------------------------------------------------------------------------------------------

/**
 * Splits inclinometer data into two groups based on the plane names from the configuration.
 *
 * @param {Object} config - The configuration object containing plane sensor information.
 * @param {Object} data - The raw inclinometer data structured as an object with filenames as keys.
 *
 * @returns {Object} - An object containing two arrays: planeOneData_raw and planeTwoData_raw.
 */

function splitInclinometerData(config, data) {
  const planeOneData_raw: InclinometerData[] = [];
  const planeTwoData_raw: InclinometerData[] = [];

  const planeOneSensors = config.plane_one_sensors || [];
  const planeTwoSensors = config.plane_two_sensors || [];

  for (const filename in data) {
    if (data.hasOwnProperty(filename)) {
      const fileData = data[filename];
      const group1 = {};
      const group2 = {};

      // Splitting the data based on the plane names from the config
      for (const key in fileData) {
        if (planeTwoSensors.length === 0 || planeOneSensors.includes(key)) {
          group1[key] = fileData[key];
        } else if (planeTwoSensors.includes(key)) {
          group2[key] = fileData[key];
        }
      }

      if (Object.keys(group1).length > 0) {
        planeOneData_raw.push({ [filename]: group1 });
      }

      if (Object.keys(group2).length > 0) {
        planeTwoData_raw.push({ [filename]: group2 });
      }
    }
  }
  return { planeOneData_raw, planeTwoData_raw };
}
//--------------------------------------------------------------------------------------------------------------------------------------
/**
 * Filters plane data based on the date of a base file specified in the configuration.
 *
 * @param {Object} config - The configuration object containing file information and the base file name.
 * @param {Array} planeOneData - The raw data for plane one, structured as an array of objects with filenames as keys.
 * @param {Array} planeTwoData - The raw data for plane two, structured as an array of objects with filenames as keys.
 *
 * @returns {Object} - An object containing two arrays: filteredPlaneOneData and filteredPlaneTwoData.
 */
function filterDataBasedOnBaseFile(config, planeOneData: InclinometerData[], planeTwoData: InclinometerData[]): { filteredPlaneOneData: InclinometerData[], filteredPlaneTwoData: InclinometerData[] } {

  const baseFileData = config.files.find(file => file.file === config.base_file);
  let baseFileDate: Date;

  if (baseFileData) {
      baseFileDate = new Date(baseFileData.data.readingDate);
  } else {
      baseFileDate = new Date('1900-01-01');
  }

  const filterByDate = (data: InclinometerData[]) => {
      return data.filter(fileData => {
          const fileName = Object.keys(fileData)[0];
          const fileDate = new Date(config.files.find(file => file.file === fileName)?.data.readingDate || '1900-01-01');
          return fileDate >= baseFileDate;
      });
  };

  const filteredPlaneOneData: InclinometerData[] = filterByDate(planeOneData);
  const filteredPlaneTwoData: InclinometerData[] = filterByDate(planeTwoData);

  return { filteredPlaneOneData, filteredPlaneTwoData };
};
//--------------------------------------------------------------------------------------------------------------------------------------
/**
 * Fetches data from the server and processes it based on the sensor type and graph type.
 *
 * @param {string} fileName - The name of the file to fetch data for.
 */

  async function fetchData(fileName, startDate: string, endDate: string) {
    try {
      let response = await axios.put(getMeasurmentsRoute, {fileName: fileName, startDate, endDate});
      if (response && response.data.resolved) {

        setGraphData(response.data.data.data);
        setGraphConfig(response.data.data.config);

        if (response.data.data.config.sensor_type === 'Inclinometer') {

          const { planeOneData_raw, planeTwoData_raw } = splitInclinometerData(response.data.data.config, response.data.data.data);
          const { filteredPlaneOneData, filteredPlaneTwoData } = filterDataBasedOnBaseFile(response.data.data.config, planeOneData_raw, planeTwoData_raw);
          console.log(filteredPlaneOneData)
          if (graphType === 'raw') {
            rawInclinometerData.current = { planeOneData_raw: filteredPlaneOneData, planeTwoData_raw: filteredPlaneTwoData };
            console.log(rawInclinometerData.current);

            const datasetsForPlaneOne = processInclinometerPlaneData_RAW(filteredPlaneOneData, response.data.data.config);
            const datasetsForPlaneTwo = processInclinometerPlaneData_RAW(filteredPlaneTwoData, response.data.data.config);

            setPlaneOneData({datasets: datasetsForPlaneOne});
            setPlaneTwoData({datasets: datasetsForPlaneTwo});
          } else {
            setGraphTo(graphType);
            setChartTitles(graphType);
          }


        }
        else {
          const datasetsForPlaneOne = processPlaneData(response.data.data.data, response.data.data.config);

          if ( response.data.data.config.linear_calculation && response.data.data.config.linear_calculation.include === true) {
            const linearCalculation = calculateLinearCalculation(datasetsForPlaneOne, response.data.data.config );
            setPlaneOneData({datasets: linearCalculation});
          }
          else {
            setPlaneOneData({datasets: datasetsForPlaneOne});
          }
        }

        MonitoringReferences.setJsonConfigText(JSON.stringify(response.data.data.config, null, 2))
        setShowGraph(true);
      }
      else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );

        alert(response.data.message);
      }
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(e, null, "\t")
      );

      alert(JSON.stringify(e, null, "\t"));
    }
  }

  graphReferences.fetchData = fetchData;

  useEffect(() => {
    filterLegendItems();
  }, [searchText]);

  useEffect(() => {
    if (chartRef1.current || chartRef2.current) {
      const chartInstance1 = chartRef1.current;
      const chartInstance2 = chartRef2.current;

      const labels1 = chartInstance1 ? chartInstance1.data.datasets.map(dataset => dataset.label) : [];
      const labels2 = chartInstance2 ? chartInstance2.data.datasets.map(dataset => dataset.label) : [];
      const combinedLabels = Array.from(new Set([...labels1, ...labels2]));

      const fileDataMap = {};
      graphConfig.files.forEach(fileObj => {
        fileDataMap[fileObj.file] = fileObj.data;
      });



      const sensorGroups = graphConfig.sensor_groups || [];
      let legendGroups: string | null = null;
      if (sensorGroups && sensorGroups.length > 0) {
        legendGroups = '<ul class="legend-list"><h4>Groups</h4>';
        sensorGroups.forEach((group, groupIndex) => {
          legendGroups += `
            <li class="legend-item" data-index="${groupIndex}">
              <input type="checkbox" id="group-checkbox-${groupIndex}" checked/>
              <label for="group-checkbox-${groupIndex}">
                \u2592 ${group.group_title}
              </label>
            </li>`;
        });
        legendGroups += '</ul>';
      }

      let legendHtml = '<ul class="legend-list"><h4>Sensors</h4>';

      combinedLabels.forEach((label, index) => {
        let baseLabel = label.split(' (')[0];
        let styleConfig = graphConfig.styles && graphConfig.styles[baseLabel] ? graphConfig.styles[baseLabel] : null;

        let labelColor = styleConfig && styleConfig.color ? styleConfig.color : '#000000';
        let pointType = styleConfig && styleConfig.pointtype ? styleConfig.pointtype : 'rect';

        let unicodeShape = {
          'circle': '⬤',
          'rect': '■',
          'rectRounded': '■',
          'rectRot': '◆',
          'cross': '✚',
          'crossRot': '✜',
          'star': '★'
        };

        let shapeChar = unicodeShape[pointType] || '■';

        const fileData = fileDataMap[label];
        const dataTitle = fileData ? `Data: ${JSON.stringify(fileData)} mm` : 'No data available';

        legendHtml += `
          <li class="legend-item" data-index="${index + sensorGroups.length}" title="${dataTitle}">
              <input type="checkbox" checked id="checkbox-${index + sensorGroups.length}"/>
              <label for="checkbox-${index + sensorGroups.length}" class="color-indicator" >
                <span class="color-box" style="color:${labelColor};">${shapeChar}</span>
                ${label}
              </label>
          </li>`;
      });

      legendHtml += '</ul>';

      if (legendRef.current) {
        legendRef.current.innerHTML = (legendGroups?legendGroups:'') + legendHtml;

        const legendCheckboxes = legendRef.current.querySelectorAll('input[type="checkbox"]');

        if (sensorGroups && sensorGroups.length > 0) {
          sensorGroups.forEach((group, groupIndex) => {
            const groupCheckbox = legendRef.current.querySelector(`#group-checkbox-${groupIndex}`);
            groupCheckbox.addEventListener('change', (event) => {
              const isChecked = (event.target as HTMLInputElement).checked;
              combinedLabels.forEach((label, index) => {
                const checkbox = legendCheckboxes[index + sensorGroups.length];
                const baseLabel = label.split(' (')[0];
                if (group.sensor_name_contains.some(str => label.includes(str)) || group.individual_sensor_names.includes(baseLabel)) {
                  checkbox.checked = isChecked;
                  [chartInstance1, chartInstance2].forEach(chartInstance => {
                    chartInstance?.data?.datasets.forEach((dataset, datasetIndex) => {
                      if (dataset.label === label) {
                        chartInstance.getDatasetMeta(datasetIndex).hidden = !isChecked;
                      }
                    });
                    chartInstance?.update();
                  });
                }
              });
            });
          });
        }

        combinedLabels.forEach((label, index) => {
          let baseLabel = label.split(' (')[0];
          const legendItem = legendRef.current.querySelector(`.legend-item[data-index='${index + sensorGroups.length}']`);

          legendItem.addEventListener('contextmenu', (event) => {
            event.preventDefault();

            if(globalTooltipContent === '') {
              globalTooltipContent = fileDataMap[baseLabel] ? `${JSON.stringify(fileDataMap[baseLabel], null, 2)}` : '';
            } else {
              globalTooltipContent = '';
            }

            chartRef1.current && chartRef1.current.update();
            chartRef2.current && chartRef2.current.update();
          });
        });

        combinedLabels.forEach((label, index) => {
          const checkbox = legendCheckboxes[index + sensorGroups.length];

          checkbox.addEventListener('click', (event) => {
            const isChecked = (event.target as HTMLInputElement).checked;

              [chartInstance1, chartInstance2].forEach(chartInstance => {
                chartInstance?.data?.datasets.forEach((dataset, datasetIndex) => {
                  if (dataset.label === label) {
                    chartInstance.getDatasetMeta(datasetIndex).hidden = !isChecked;
                  }
                });
                chartInstance?.update();
              });
          });
        });
      }
    }
    setChartTitles(graphType);


  }, [chartRef1, chartRef2, legendRef, graphConfig]);

//--------------------------------------------------------------------------------------------------------------------------------------
/**
 * Filters legend items in the chart instances based on the search text and updates their visibility.
 */
  const filterLegendItems = () => {
    if (chartRef1.current) {
      const chartInstance1 = chartRef1.current;
      const chartInstance2 = chartRef2.current;

      const legendItems1 = chartInstance1.legend.legendItems.reduce((acc, item) => {
        acc[item.text] = item;
        return acc;
      }, {});

      const legendItems2 = chartInstance2
        ? chartInstance2.legend.legendItems.reduce((acc, item) => {
            acc[item.text] = item;
            return acc;
          }, {})
        : {};

      Object.keys(legendItems1).forEach((text) => {
        const isHidden = !text.toLowerCase().includes(searchText.toLowerCase());
        const meta1 = chartInstance1.getDatasetMeta(legendItems1[text].datasetIndex);

        meta1.hidden = isHidden;


        if (legendItems2[text]) {
          const meta2 = chartInstance2.getDatasetMeta(legendItems2[text].datasetIndex);
          meta2.hidden = isHidden;
        }
      });

      chartInstance1.update();
      if (chartInstance2) {
        chartInstance2.update();
      }
    }
  };
  const currentDate = new Date();
  const fourteenDaysAgo = new Date();
    fourteenDaysAgo.setDate(currentDate.getDate() - 14);
  const chartOptions: any = {

    scales: {
      x: {
        type: graphConfig.x_axisType,
        title: {
          display: true,
          text: graphConfig.x_axisName,
          color:'black',
          font: {
            size: 12
          }
        },
        time: {
          parser: 'YYYY-MM-DD HH:mm:ss',
          tooltipFormat: 'YYYY-MM-DD HH:mm',
          unit: 'hour',
            displayFormats: {
              hour: 'YYYY-MM-DD HH:mm'
            }
        },/*
        min: graphConfig.x_axis_min? graphConfig.x_axis_min: null,
        max: graphConfig.x_axis_max? graphConfig.x_axis_max: null, */
        ticks: {
          font: {
            size: 10
          }
        },

      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: graphConfig.y_axisName,
          color: 'black',
          font: {
            size: 12
          }
        },
        min: graphConfig.y_axis_min? graphConfig.y_axis_min: null,
        max: graphConfig.y_axis_max? graphConfig.y_axis_max: null,
        ticks: {
          font: {
            size: 10
          }
        },

      },

    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 105/260,
    plugins: {
      decimation: {
        enabled: true,
        algorithm: 'min-max', // or 'lttb'
        samples: 1000 // The number of samples to retain
      },
      plugins: [customZeroLinePlugin],
      title: {
        display: true,
        text: 'Your Graph Title',
        font: {
          size: 24,
          weight: 'bold',
          family: 'Arial',
          color: 'black',
        },
        padding: {
          top: 10,
          bottom: 10,
        },
        color: 'black'
      },
      tooltip: {
        enabled: true,
        mode: 'point',
        intersect: true,
        displayColors: false,
        backgroundColor: 'rgba(0,0,0,1)',
        titleFont: { size: 20, weight: 'bold', family: 'Arial' },
        bodyFont: { size: 18, family: 'Arial'},
        footerFont: { size: 12, family: 'Arial' },
        padding: 10,
        caretSize: 10,
        caretPadding: 10,
        boxWidth: 100,
        callbacks: {
          title: function() {
            const titleX = graphConfig.x_axisName ? `X: ${graphConfig.x_axisName}`: '';
            const titleY = graphConfig.y_axisName ? ` | Y: ${graphConfig.y_axisName}`: '';
            return `${titleX}${titleY}`;
          },
          label: function(tooltipItem) {
            const label = tooltipItem.dataset.label;
            const sensorType = tooltipItem.dataset.sensorType;
            const xValue = tooltipItem.label;
            const yValue = tooltipItem.formattedValue;

            const sensorTypePart = sensorType ? `${sensorType} | ` : '';

            return `${label} | ${sensorTypePart}X: ${xValue}, Y: ${yValue}`;
          }
        }
      },

      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
          onPan: ({ chart }) => {
            const sourceChart = chartRef1.current === chart ? chartRef1.current : chartRef2.current;
            const targetChart = chartRef1.current === chart ? chartRef2.current : chartRef1.current;
            syncZoom(sourceChart, targetChart);
          }
        },
        zoom: {
          mode: 'xy',
          wheel: { enabled: true },
          onZoom: ({ chart }) => {
            const sourceChart = chartRef1.current === chart ? chartRef1.current : chartRef2.current;
            const targetChart = chartRef1.current === chart ? chartRef2.current : chartRef1.current;
            syncZoom(sourceChart, targetChart);
          }
        }
      },
      legend: {
        display: false,
        position: 'left',
        align: 'start',
        labels: {
          boxWidth: 15,
          filter: function(legendItem, chartData) {
            const label = legendItem.text;
            const index = chartData.datasets.findIndex(dataset => dataset.label === label);
            return index === legendItem.datasetIndex;
          }
        },
        onClick: function(event, legendItem, legend) {
          const index = legend.chart.data.datasets.findIndex(dataset => dataset.label === legendItem.text);
          if (index !== -1) {
            legend.chart.data?.datasets.forEach(dataset => {
              if (dataset.label === legendItem.text) {
                dataset.hidden = !dataset.hidden;
              }
            });
            legend.chart.update();
          }
        }
      },
      myCustomPlugin: backgroundPlugin,
      //customLegendPlugin: customLegendPlugin,
    },
    hover: {
      mode: 'point',
      intersect: true
    }
  };


  const chartOptionsPlaneOne_init: any = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      title: {
        ...chartOptions.plugins.title,
        text: graphConfig.graph_titles?.[0]?? 'Graph 1',
      },
    },
  };

  const chartOptionsPlaneTwo_init: any = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      title: {
        ...chartOptions.plugins.title,
        text: graphConfig.graph_titles?.[1]?? 'Graph 2',
      },
    },
  };
  const [chartOptionsPlaneOne, setChartOptionsPlaneOne] = useState<any>(chartOptionsPlaneOne_init);
  const [chartOptionsPlaneTwo, setChartOptionsPlaneTwo] = useState<any>(chartOptionsPlaneTwo_init);


  function setChartTitles(measurementType) {
    const newOptionsPlaneOne = {
      ...chartOptions,
      plugins: {
        ...chartOptions.plugins,
        title: {
          ...chartOptions.plugins.title,
          text: graphConfig.graph_titles?.[0] ? `${graphConfig.graph_titles?.[0]} - ${measurementType}` : 'Graph 1',
        },
      },
    };

    const newOptionsPlaneTwo = {
      ...chartOptions,
      plugins: {
        ...chartOptions.plugins,
        title: {
          ...chartOptions.plugins.title,
          text: graphConfig.graph_titles?.[1] ? `${graphConfig.graph_titles?.[1]} - ${measurementType}` : 'Graph 2',
        },
      },
    };

    setChartOptionsPlaneOne(newOptionsPlaneOne);
    setChartOptionsPlaneTwo(newOptionsPlaneTwo);
  };


  function setGraphTo (selectedGraph) {

    setChartTitles(selectedGraph);
    setGraphType(selectedGraph);
    if (selectedGraph === 'raw') {

      const datasetsForPlaneOne = processInclinometerPlaneData_RAW(rawInclinometerData.current.planeOneData_raw, graphConfig);
      const datasetsForPlaneTwo = processInclinometerPlaneData_RAW(rawInclinometerData.current.planeTwoData_raw, graphConfig);

      setPlaneOneData({datasets: datasetsForPlaneOne});
      setPlaneTwoData({datasets: datasetsForPlaneTwo});
    }
    else if (selectedGraph === 'mean') {
      const meanDeviationPlaneOne = calculateMeanDeviation(rawInclinometerData.current.planeOneData_raw, graphConfig);
      const meanDeviationPlaneTwo = calculateMeanDeviation(rawInclinometerData.current.planeTwoData_raw, graphConfig);

      setPlaneOneData({datasets: meanDeviationPlaneOne});
      setPlaneTwoData({datasets: meanDeviationPlaneTwo});
    }
    else if (selectedGraph === 'incremental') {
      const IncrementalPlaneOne = calculateIncremental(rawInclinometerData.current.planeOneData_raw, graphConfig);
      const IncrementalPlaneTwo = calculateIncremental(rawInclinometerData.current.planeTwoData_raw, graphConfig);

      setPlaneOneData({datasets: IncrementalPlaneOne});
      setPlaneTwoData({datasets: IncrementalPlaneTwo});
    }
    else if (selectedGraph === 'cumulative') {
      const cumulativePlaneOne = calculateCumulative(rawInclinometerData.current.planeOneData_raw, graphConfig);
      const cumulativePlaneTwo = calculateCumulative(rawInclinometerData.current.planeTwoData_raw, graphConfig);

      setPlaneOneData({datasets: cumulativePlaneOne});
      setPlaneTwoData({datasets: cumulativePlaneTwo});
    }
    else if (selectedGraph === 'checksum') {
      const checksumPlaneOne = calculateCheckSum(rawInclinometerData.current.planeOneData_raw, graphConfig);
      const checksumPlaneTwo = calculateCheckSum(rawInclinometerData.current.planeTwoData_raw, graphConfig);

      setPlaneOneData({datasets: checksumPlaneOne});
      setPlaneTwoData({datasets: checksumPlaneTwo});
    }
    else if (selectedGraph === 'absolute') {
      const checksumPlaneOne = calculateAbsolute(rawInclinometerData.current.planeOneData_raw, graphConfig);
      const checksumPlaneTwo = calculateAbsolute(rawInclinometerData.current.planeTwoData_raw, graphConfig);

      setPlaneOneData({datasets: checksumPlaneOne});
      setPlaneTwoData({datasets: checksumPlaneTwo});
    }
  }
  const syncZoom = (sourceChart, targetChart) => {
    if (!targetChart) return;

    targetChart.options.scales.x.min = sourceChart.scales.x.min;
    targetChart.options.scales.x.max = sourceChart.scales.x.max;
    targetChart.options.scales.y.min = sourceChart.scales.y.min;
    targetChart.options.scales.y.max = sourceChart.scales.y.max;

    targetChart.update();
  };

  const createD3LineChart = (datasets: any[], chartOptions: any, measurementType): string => {
    console.log(datasets, chartOptions);
    let unicodeShape: { [key: string]: string } = {
      'circle': '⬤',
      'rect': '■',
      'rectRounded': '■',
      'rectRot': '◆',
      'cross': '✚',
      'crossRot': '✜',
      'star': '★'
    };
    const isDateAxis = measurementType !== "linear";

    const margin = { top: 30, right: 30, bottom: 35, left: 50 };
    const width = isDateAxis? (942 - margin.top - margin.bottom) :(396.8503937 - margin.left - margin.right); // Adjusted width
    const height = isDateAxis? (396.8503937*2 - margin.left - margin.right):(1046.9291339 - margin.top - margin.bottom); // Adjusted height
    const viewBox = isDateAxis? width + margin.left + margin.right+140 : width + margin.left + margin.right;

    const svg: any = d3.create("svg")
        .attr("xmlns", "http://www.w3.org/2000/svg")
        .attr("width", width + margin.left + margin.right) // Include margins in total width
        .attr("height", height + margin.top + margin.bottom) // Include margins in total height
        .attr("viewBox", `0 0 ${viewBox} ${height + margin.top + margin.bottom}`) // Adjust viewBox to include margins
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);




    const x: any = isDateAxis ? d3.scaleTime().range([0, width]) : d3.scaleLinear().range([0, width]);
    const y: any = d3.scaleLinear().range([height, 0]);

    const valueline = d3.line()
        .defined((d: any) => d.y !== null)  // Skip over points where y is null
        .x((d: any) => x(d.x))
        .y((d: any) => d.y !== null ? y(d.y) : null);


      if (isDateAxis) {
        if (chartOptions.scales.x.min !== null && chartOptions.scales.x.max !== null) {
          x.domain([new Date(chartOptions.scales.x.min), new Date(chartOptions.scales.x.max)]);
        } else {
          x.domain(d3.extent(datasets.flatMap((d: any) => d.data), (d: any) => new Date(d.x)));
        }
      } else {
        if (graphConfig.x_axis_max !== null && graphConfig.x_axis_min !== null) {
          x.domain([graphConfig.x_axis_min, graphConfig.x_axis_max]);
        } else {
          x.domain(d3.extent(datasets.flatMap((d: any) => d.data), (d: any) => d.x));
        }
      }

      // Set the domain for the y-axis
      if (graphConfig.y_axis_min !== null && graphConfig.y_axis_max !== null) {
        y.domain([graphConfig.y_axis_min, graphConfig.y_axis_max]);
      } else {
        y.domain(d3.extent(datasets.flatMap((d: any) => d.data), d => d.y));
      }
    // Gridlines
    function makeXGridlines() { return d3.axisBottom(x).ticks(40) }
    function makeYGridlines() { return d3.axisLeft(y).ticks(40) }

    svg.append("line")
        .attr("clip-path", "url(#clip)")
        .style("stroke", "#00a76d")
        .style("stroke-width", 1)
        .style("stroke-dasharray", ("5, 5"))
        .attr("x1", 0)
        .attr("y1", y(0))
        .attr("x2", width)
        .attr("y2", y(0));

    // Vertical line at X = 0 (if applicable)
    if (!isDateAxis && x.domain()[0] <= 0 && x.domain()[1] >= 0) {
        svg.append("line")
            .attr("clip-path", "url(#clip)")
            .style("stroke", "#00a76d")
            .style("stroke-width", 1)
            .style("stroke-dasharray", ("5, 5"))
            .attr("x1", x(0))
            .attr("y1", 0)
            .attr("x2", x(0))
            .attr("y2", height);
    }
    svg.append("defs").append("clipPath") // append a defs (for definitions) element to your SVG
      .attr("id", "clip") // assign an ID
      .append("rect")
      .attr("width", width) // set the width
      .attr("height", height); // set the height

    svg.append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0,${height})`)
      .call(makeXGridlines().tickSize(-height).tickFormat(() => ""))
      .selectAll(".tick line").style("opacity", 0.1);

    svg.append("g")
      .attr("class", "grid")
      .call(makeYGridlines().tickSize(-width).tickFormat(() => ""))
      .selectAll(".tick line").style("opacity", 0.1);

    if (chartOptions && chartOptions.plugins && chartOptions.plugins.title) {
      svg.append("text")
        .attr("x", width / 2)
        .attr("y", 0 - (margin.top / 2))
        .attr("text-anchor", "middle")
        .style("text-decoration", "underline")
        .text(chartOptions.plugins.title.text);
    }

    // X Axis Title
    if (chartOptions && chartOptions.scales && chartOptions.scales.x) {
      svg.append("text")
        .attr("transform", `translate(${width / 2}, ${height + margin.bottom-3})`)
        .style("text-anchor", "middle")
        .text(chartOptions.scales.x.title.text)
    }

    // Y Axis Title
    if (chartOptions && chartOptions.scales && chartOptions.scales.y) {
      svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - (height / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(chartOptions.scales.y.title.text)
    }


    const legendItemHeight = 20;
    const legendPadding = 10;

    const tempSvg = d3.select("body").append("svg").attr("visibility", "hidden");
    let maxLabelWidth = 0;

    datasets.forEach((dataset) => {
      tempSvg.append("text")
        .text(dataset.label)
        .each(function () {
          maxLabelWidth = Math.max(maxLabelWidth, this.getComputedTextLength());
        })
        .remove();
    });
    tempSvg.remove();

    let legendY = height - margin.bottom /* - datasets.length * legendItemHeight */;

    datasets.forEach((dataset, index) => {
      const pathData: any = valueline(dataset.data);

      if (pathData.includes("NaN")) {
        console.error(`Error in dataset ${index}: Path data contains NaN`, dataset);
        dataset.data.forEach((d, i) => {
          if (isNaN(x(d.x)) || isNaN(y(d.y))) {
            console.log(`Data point ${i} is problematic:`, d);
          }
        });
      }

      svg.append("path")
          .data([dataset.data])
          .attr("class", "line")
          .style("stroke", dataset.borderColor)
          .style("stroke-width", dataset.borderWidth/2)
          .style("fill", "none")
          .attr("d", valueline)
          .attr("clip-path", "url(#clip)");

      dataset.data.forEach((d: any) => {
        const unicodeChar = unicodeShape[dataset.pointStyle] || unicodeShape['circle'];

        svg.append("text")
        .attr("x", x(d.x))
        .attr("y", y(d.y))
        .attr("dy", ".35em")
        .text(unicodeChar)
        .style("font-size", dataset.pointRadius*2 + 'px')
        .style("fill", dataset.borderColor)
        .attr("text-anchor", "middle")
        .attr("clip-path", "url(#clip)");
      });
      const legend = svg.append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${width - margin.right - legendPadding - maxLabelWidth/2/* legendPadding */},${legendY})`);

      legend.append('text')
        .attr('x', 0)
        .attr('y', 10)
        .style('fill', dataset.backgroundColor)
        .style('opacity', 0.7)
        .style('font-family', 'Arial, sans-serif')
        .style('font-size', '10px')
        .text(unicodeShape[dataset.pointStyle]);

    legend.append("text")
      .attr("x", 15)
      .attr("y", 10)
      .text(dataset.label)
      .style("font-size", "10px")
      .style("opacity", 0.7);

      legendY -= legendItemHeight;
    });


    if (isDateAxis) {
      svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x)
          .ticks(d3.timeWeek.every(1))
          .tickFormat((d: any) => d3.timeFormat("%Y-%m-%d %H:%M")(d))
        )
        .selectAll("text")
        .style("text-anchor", "start")
        .attr("dy", "-0.8em")
        .attr("dx", "1em")
        .attr("transform", "rotate(90)")
        .style("alignment-baseline", "middle");
    } else {
      svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x))
        .selectAll("text")
        .style("text-anchor", "middle");
    }

    svg.append("g")
      .call(d3.axisLeft(y).ticks(40));

    const svgElement = svg.node().parentNode;
    const svgString = new XMLSerializer().serializeToString(svgElement);

    console.log(svgString);
    return svgString;
  };


  const svgToDataURL = (svgStr, widthMM, heightMM) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const dpi = 300;
            const mmToPx = dpi / 25.4;
            canvas.width = widthMM * mmToPx;
            canvas.height = heightMM * mmToPx;
            const ctx: any = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            canvas.remove();
            resolve(canvas.toDataURL('image/png'));
        };

        img.onerror = () => {
            console.error('Error loading SVG: ', svgStr);
            reject(new Error('Error converting SVG to PNG'));
        };

        const svgBlob = new Blob([svgStr], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);
        img.src = url;
    });
};


  const downloadPDF = async () => {
    console.log('here');
    const loadImage = () => new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = `${process.env.PUBLIC_URL}/logoirgo.png`;;
    });

    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    });

  const topSectionHeight = 43;
  const columnWidth = 70;
  const margin = 5;
  const leftSideMargin = 20;

  doc.setDrawColor(0);
  doc.setFillColor(255, 255, 255);
  doc.rect(0, 0, 210, topSectionHeight, 'FD');

  console.log(graphConfig.reportData);

  const reportData = graphConfig.reportData;
  const reportDataKeys = Object.keys(reportData);
  const splitIndex = Math.ceil(reportDataKeys.length / 2);

  doc.setFont('Times-Roman', 'normal');

  const maxTextHeight = topSectionHeight;
  const fontSize = 9;
  doc.setFontSize(fontSize);

  const lineHeight = fontSize * 0.352777778;
  const maxLines = Math.floor(maxTextHeight / lineHeight);

  const maxKeysPerColumn = Math.floor(maxLines / 2);
  const leftColumnKeys = reportDataKeys.slice(0, maxKeysPerColumn);
  const rightColumnKeys = reportDataKeys.slice(maxKeysPerColumn, maxKeysPerColumn * 2);

  leftColumnKeys.forEach((key, index) => {
      doc.text(`${key}: ${reportData[key]}`, margin + leftSideMargin, topSectionHeight / 3 + (index * lineHeight));
  });

  rightColumnKeys.forEach((key, index) => {
      doc.text(`${key}: ${reportData[key]}`, columnWidth * 2 + margin, topSectionHeight / 3 + (index * lineHeight));
  });

  // Loading and positioning the logo
  const logo = await loadImage();
  const desiredHeight = 10;
  const aspectRatio = logo.width / logo.height;
  const scaledWidth = desiredHeight * aspectRatio;
  doc.addImage(logo, 'PNG', 210 / 2 - scaledWidth / 2, 15, scaledWidth, desiredHeight);

    const fullA4Height =  graphConfig.sensor_type === 'Inclinometer'? 250 : 290;
    const fullA4Width = graphConfig.sensor_type === 'Inclinometer'? 190 : 210;
    const selectedWidth = graphConfig.sensor_type === 'Inclinometer'? fullA4Width/2 : fullA4Height
    const selectedHeight = graphConfig.sensor_type === 'Inclinometer'? fullA4Height : fullA4Width


    const svgStringOne = createD3LineChart(planeOneData.datasets, chartOptionsPlaneOne, graphConfig.x_axisType === 'linear'?'linear':'date');
    const imgDataOne: any = await svgToDataURL(svgStringOne, selectedWidth, selectedHeight);
    console.log(imgDataOne);

    // Add the first image to the PDF
    if (graphConfig.sensor_type === 'Inclinometer') {
      doc.addImage(imgDataOne, 'PNG', leftSideMargin, topSectionHeight, selectedWidth, selectedHeight, 'incl_1', 'FAST');
    } else {
      // For other sensor types
      const newWidth = selectedWidth;
      const newHeight = selectedHeight;
      const newPosX = newHeight;
      const newPosY = topSectionHeight * 2;
      doc.addImage(imgDataOne, 'PNG', newPosX, newPosY, newWidth, newHeight, 'other', 'FAST', 90);
    }

    // Convert the second SVG to a PNG only if it's an Inclinometer
    if (graphConfig.sensor_type === 'Inclinometer') {
      const svgStringTwo = createD3LineChart(planeTwoData.datasets, chartOptionsPlaneTwo, graphConfig.x_axisType === 'linear'?'linear':'date');
      const imgDataTwo: any = await svgToDataURL(svgStringTwo, selectedWidth, selectedHeight);
       console.log(imgDataTwo);
     doc.addImage(imgDataTwo, 'PNG', selectedWidth + leftSideMargin, topSectionHeight, selectedWidth, selectedHeight, 'incl_2', 'FAST');
    }

    doc.save(`${graphReferences.headInfo.name}_${graphType}.pdf`);
  };

  graphReferences.downloadPDF = downloadPDF;


  return (
    <>
      { showGraph &&
        <div className="graph" key={graphKey} style = {{display: (planeOneData || planeTwoData)? '': 'none'}}>


          {graphConfig.sensor_type === 'Inclinometer' ?
            <>
              <select onChange={(e) => setGraphTo(e.target.value)} style = {{textAlign:'center'}}>
                  <option value="raw">Raw</option>
                  <option value="checksum">Checksum</option>
                  <option value="absolute">Absolute</option>
                  <option value="mean">Mean Deviation</option>
                  <option value="incremental">Incremental</option>
                  <option value="cumulative">cumulative</option>
              </select>
              <div className="graph-item">
                <Line key={"plane-one"} ref={chartRef1} data={planeOneData} options={chartOptionsPlaneOne} />
              </div>
              <div className="graph-item">
                <Line key={"plane-two"} ref={chartRef2} data={planeTwoData} options={chartOptionsPlaneTwo} />
              </div>
            </>
            :
            <div className="graph-item full-width">
              <Line key={"plane-one"} ref={chartRef1} data={planeOneData} options={chartOptionsPlaneOne} />
            </div>
          }
        </div>
      }


    </>
  );
};

interface FtpSettingsInterface {
  ftp_automated: boolean;
  remote_path: string;
  remote_file_identifier: string;
  ActionLogReferences: ActionLogReferences;
  node_uuid: string;
  refreshFtpData: () => void;
}
const FtpSettings: React.FC<FtpSettingsInterface> = ({
  ActionLogReferences,
  ftp_automated,
  remote_path,
  remote_file_identifier,
  node_uuid,
  refreshFtpData }) => {

  const [edit, setEdit] = useState<boolean>(false);
  const [ftpFilesList, setFtpFilesList] = useState<string[]>();
  const [openPage, setOpenPage] = useState<string>('');
  const [remoteData, setRemoteData] = useState({
    ftp_automated,
    remote_path,
    remote_file_identifier,
    uuid: node_uuid
  })
//------------------------------------------------------------------------------------------
  /**
 * Fetches the list of files from the FTP server and updates the state or logs errors.
 *
 * @returns {Promise<void>}
 */
  async function fetchFtpFilesList (): Promise<void> {
    try {
      let response = await axios.put(fetchFtpFileListRoute, {remote_path});

      if (response && response.data.resolved) {
        setFtpFilesList(response.data.data);
        console.log(response.data.data);
        ActionLogReferences.updateLogString(
          'Updating data OK!',
          LogMessageType.Success,
          JSON.stringify(response.data.message, null, "\t")
        );
      } else {
        ActionLogReferences.updateLogString(
          'Error Updating data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
      }
    } catch (error) {
      ActionLogReferences.updateLogString(
        'Error Updating data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
    }
  }
  const handleFilesClick = async() => {
    if (!ftpFilesList) {
      await fetchFtpFilesList();
    }
    setOpenPage('Files');
  }

  async function updateFtpData(): Promise<boolean> {
    try {
      let response = await axios.put(updateDatabaseFTPdata, remoteData);

      if (response && response.data.resolved ) {
        refreshFtpData();
        return true;
      } else {
        ActionLogReferences.updateLogString(
          'Error Updating data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
        return false
      }
    } catch (error) {
      ActionLogReferences.updateLogString(
        'Error Updating data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
      return false
    }
  };

  const handleUpdateFtpData = async() => {
    const ftpUpdated = await updateFtpData();
    if (ftpUpdated) {
      setEdit(false);
    }
  }

  return (
    <div style = {{backgroundColor:'white', height:'100%'}}>
      <div style = {{display:'flex', flexDirection:'row', width: '100%', justifyContent:'space-between', position:'sticky', top:'0'}}>
        <button className = {openPage === 'Settings'? 'globals--green-button': 'globals--regular-button'} onClick={() => setOpenPage('Settings')}>
          <IoIosSettings /> Settings
        </button>
        <button className = {openPage === 'Files'? 'globals--green-button': 'globals--regular-button'} onClick={handleFilesClick}>
          <RiFileTransferLine /> Files at FTP server
        </button>

        <button className = 'globals--regular-button'
              onClick={() => refreshFtpData()}><IoMdRefresh /> Refresh FTP Data</button>
      </div>

      {openPage === 'Settings' &&
      <>
      <div>
       <strong>FTP Path:</strong>
        {edit ? (
          <input
            type='text'
            value={remoteData.remote_path}
            onChange={(e) =>
              setRemoteData((prev) => ({ ...prev, remote_path: e.target.value }))
            }
          />
        ) : (
          <div>{remoteData.remote_path || 'No data available'}</div>
        )}
      </div>
      <div>
        <strong>FTP Remote File Identifier:</strong>
        {edit ? (
          <input
            type='text'
            value={remoteData.remote_file_identifier}
            onChange={(e) =>
              setRemoteData((prev) => ({
                ...prev,
                remote_file_identifier: e.target.value
              }))
            }
          />
        ) : (
          <div>{remoteData.remote_file_identifier || 'No data available'}</div>
        )}
      </div>

      {edit?
        <button onClick = {handleUpdateFtpData} className = 'globals--regular-button'>
          Save
        </button>
      :
        <button onClick={() => setEdit(!edit)} className = 'globals--regular-button'>
          Edit
        </button>
      }
      </>
      }


      {openPage === 'Files' &&
        <div className='global-list'>
          <h3>Files <span style={{fontSize: '12px'}} title='(remote_path at FTP server)   (remote_file_identifier)'>({remote_path})  ({remote_file_identifier})</span></h3>
          <table>
            <thead>
              <tr>
                <th>File name</th>
              </tr>
            </thead>
            <tbody>
              {ftpFilesList?.map((file: any, index) => (
                <tr key={index}>
                  <td><b>{file.name}</b> ({file.rawModifiedAt})</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
};

const MyLocalPDFViewer = ({pdfFileName, pdfReferences, ActionLogReferences}) => {
  const [scale, setScale] = useState(1);
  const [pdfURL, setPdfURL] = useState('');
  const containerRef = useRef<any>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [previousPosition, setPreviousPosition] = useState<any>(null);

  const containerStyle = {
    overflow: 'hidden',
    backgroundColor: '#f0f0f0',
    cursor: isDragging ? 'grabbing' : 'grab',
  };
  useEffect(() => {
    if (pdfFileName) {
      fetchPDF();
    }

    return () => {
      if (pdfURL) {
        URL.revokeObjectURL(pdfURL);
      }
    };
  }, [pdfFileName]);


  const handleMouseDown = (event) => {
    setIsDragging(true);
    setPreviousPosition({ x: event.clientX, y: event.clientY });
  };

  const fetchPDF = async () => {
    try {
      const response = await axios.get(`${servePdfFileRoute}/${pdfFileName}`, {
        responseType: 'blob'
      });

      const fileURL = URL.createObjectURL(new Blob([response.data]));
      setPdfURL(fileURL);
    } catch (error) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t")
      );
    }
  };

  pdfReferences.fetchPDF = fetchPDF;

  const handleMouseUp = () => {
    setIsDragging(false);
    setPreviousPosition(null);
  };
  const handleWheel = (e) => {
    e.preventDefault();
    const zoomFactor = e.deltaY < 0 ? 0.1 : -0.1;
    setScale(prevScale => Math.max(0.1, prevScale + zoomFactor));
  };


  const handleMouseMove = (event) => {
    if (isDragging && previousPosition && containerRef.current) {
      const container = containerRef.current;
      const deltaX = event.clientX - previousPosition.x;
      const deltaY = event.clientY - previousPosition.y;
      container.scrollLeft -= deltaX;
      container.scrollTop -= deltaY;
      setPreviousPosition({ x: event.clientX, y: event.clientY });
    }
  };

  return (
    <div className = 'content2'
      style={containerStyle}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onTouchMove={handleMouseMove}
      onWheel={handleWheel}
    >
      <Document file={pdfURL}>
        <Page pageNumber={1} scale={scale} renderTextLayer={false}/>
      </Document>

    </div>
  );
};

function getRandomPointStyle() {
  const pointStyles = ['circle', 'rect', 'rectRounded', 'rectRot', 'cross', 'crossRot', 'star'];
  return pointStyles[Math.floor(Math.random() * pointStyles.length)];
}

//------------------------------------------------------------------------------------------------------
export default Monitoring;
//------------------------------------------------------------------------------------------------------
